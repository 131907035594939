import { Avatar, Drawer } from 'antd';
import React, { useState } from 'react'
import { CustomInput, CustomSelect } from '../forms/CustomForms';
import axiosInstance from '../../api/axiosInstance';
import { useEffect } from 'react';
import { Button, Offcanvas, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { defaultsImage } from '../../var_func/var_func';
import { useCookies } from 'react-cookie';
import { PaginationPage } from '../global';
import moment from 'moment';

export function SortNews(prpos) {
    const { onClose, open } = prpos;
    const [page, setPage] = useCookies('');
    const { sort_News_list } = page;
    const [sortValues, setSortValues] = useState({
        type: '',
        sort: '',
        is_active: '',
    })
    const [SortData, setSortData] = useState({
        managements: [],
        list: [],
        listCount: 0
    })
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    const [drawer, setDrawer] = useState(false);
    const change = (name, value) => {
        setSortValues((state) => {
            return { ...state, [name]: value }
        })
    }
    const getFormData = (name, value) => {
        setSortData((state) => {
            return { ...state, [name]: value }
        })
    }
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    const showChildrenDrawer = () => {
        setDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setDrawer(false);
    };
    useEffect(() => {
        //get death
        async function getData() {
            const sort = `is_active=${sortValues.is_active}&type=${sortValues.type}&sort=${sortValues.sort}`;
            if (drawer) {
                await axiosInstance.get(`/news/api/v1/news/?${sort}&page_size=100&page=${sort_News_list ? sort_News_list : 1}`)
                    .then(res => {
                        getFormData('list', res.data.results)
                        getFormData('listCount', res.data.count)
                    }).catch(err => {
                        if (err.response.data.detail === 'Invalid page.') {
                            setPage('sort_News_list', 1)
                        }
                    })
            }

        }
        getData()
    }, [sortValues, drawer, sort_News_list])



    return (
        <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>فرز الأخبار</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div class="container-fluid">
                    <div className='row justify-content-center g-2'>
                        <div className='col-12'>
                            <label>نوع الخبر</label>
                            <CustomSelect
                                icon='grade'
                                name='type'
                                onChange={(e) => change('type', e === undefined ? '' : e)}
                                onClear={() => change('type', '')}
                            >
                                <option value={0}>عاجل</option>
                                <option value={1}>خبر اعتيادي</option>
                            </CustomSelect>
                        </div>
                        <div className='col-12'>
                            <label>أولوية الخبر</label>
                            <CustomSelect
                                icon='sort'
                                name='sort'
                                onChange={(e) => change('sort', e === undefined ? '' : e)}
                                onClear={() => change('sort', '')}
                            >
                                <option value={0}>مهم</option>
                                <option value={1}>اعتيادي</option>
                            </CustomSelect>
                        </div>
                        <div className='col-12'>
                            <label>حالة الخبر</label>
                            <CustomSelect
                                icon='done'
                                name='is_active'
                                onChange={(e) => change('is_active', e === undefined ? '' : e)}
                                onClear={() => change('is_active', '')}
                            >
                                <option value={true}>منشور</option>
                                <option value={false}>غير منشور</option>
                            </CustomSelect>
                        </div>
                    </div>
                    <div className='row justify-content-center g-2 mt-5 '>
                        <Button variant="" className='but-all w-100' onClick={showChildrenDrawer}>
                            معاينة النتائج
                        </Button>
                    </div>
                </div>

                <Offcanvas show={drawer} onHide={onChildrenDrawerClose} className='offcanvas-edit'>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>نتائج الفرز</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='table-responsive'>
                            <Table className="align-items-center table-flush">

                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">عنوان الخبر</th>
                                        <th scope="col">الوكالة</th>
                                        <th scope="col">نوع الخبر</th>
                                        <th scope="col">أولوية الخبر</th>
                                        <th scope="col">حالة الخبر</th>
                                        <th scope="col">تاريخ النشر</th>
                                        <th scope="col">تاريخ الرفع</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {SortData.list.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td width='20%'>
                                                    <Link to={`/news/list/id/${item.id}/name/${item.title}`}> {item.title}</Link>
                                                </td>
                                                <td width='20%'>{item.author}</td>
                                                <td width='20%'>{item.type === 0 ? 'عاجل' : "خبر اعتيادي"}</td>
                                                <td width='20%'>{item.sort === 0 ? 'مهم' : "اعتيادي"}</td>
                                                <td width='20%'>{item.is_active ? 'منشور' : "غير منشور"}</td>
                                                <td dir='ltr' width={1}>{moment(item.publication_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                <td dir='ltr' width={1}>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>


                        <div className="col-12">
                            <PaginationPage
                                defaultCurrent={!sort_News_list ? 1 : Number(sort_News_list)}
                                total={SortData.listCount}
                                defaultPageSize={100}
                                onChange={(e) => setPage('sort_News_list', e === undefined ? '' : e)}
                            />
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </Offcanvas.Body>
        </Offcanvas>
    )
}


