import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { styleNav } from '../../var_func/var_func';
export default function Admin() {
    const [open, setOpen] = React.useState(true);
    const onClick = () => {
        setOpen(!open)
    }
    React.useEffect(() => {
        function pageLayout() {
            var element = document.getElementById("page-content");
            var navbar = document.getElementById("navbar");
            var width = window.innerWidth;

            if (open) {
                element.classList.add("add-mr");
                navbar.classList.add("add-mr");
            } else {
                element.classList.remove("add-mr");
                navbar.classList.remove("add-mr");
            }
        }
        pageLayout()
        styleNav()
    }, [open])
    return (
        <div className='admin-layout'>
            <Navbar onClick={onClick} open={open} />
            <Sidebar open={open} handleClose={() => setOpen(false)} />
        </div>
    )
}