import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';
import { getTimeSinceDate, itemType, mediaURL } from '../../var_func/var_func';
import { Button } from 'react-bootstrap';
import { DeletePop } from '../../components/forms/PopForm';
import { PageHeader, ButtonGroups } from '../../components/global';
import JSZip from "jszip";

export function ItemsSurvey({ type }) {
    const { id, name } = useParams()
    const [data, setData] = useState({
        item: '',
    })
    const [open, setOpen] = useState({
        one: false,
        two: false,
        three: false,
        password: false,
        status: false,
        delete: false,
        type: 777,
        id: 0,
        item: null
    });

    const [post, setPost] = useState({
        count: 0,
    })
    const drawer = (name, value, type) => {
        if (type && type !== 0) {
            setOpen((state) => {
                return { ...state, [name]: value, type: type }
            })
        } else {
            setOpen((state) => {
                return { ...state, [name]: value }
            })
        }
    }
    const getApiData = (name, value) => {
        setData((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    const url = '/contributions/api/v1/contributions/';
    const title = 'الأستمارات';
    const path = '/contributions/list/';
    useEffect(() => {
        //get death

        async function getData() {
            await axiosInstance.get(`${url}${id}/`)
                .then(res => {
                    getApiData('item', { ...res.data })
                });

        };
        getData();
    }, [id, post.count]);
    const zip = new JSZip();
    const infoText = (item) => {
        const text = `
        الرمز التعريفي : ${item.uid}
        الأسم الثلاثي : ${item.name}
        رقم الهاتف : ${item.phone}
        البريد الألكتروني :${item.email}
        الجامعة : ${item.university}
        الكلية : ${item.college}
        القسم : ${item.section}
        عنوان البحث : ${item.search_title}
        الباحثون : ${item.search_names}
        ملخص البحث :
         ${item.text}
        `
        return text;
    }
    const downloadFiles = async (files, name, item) => {
        const urls = files.map(item => (item.file));
        const fileName = name.replaceAll(" ", "_")
        zip.file("information.txt", infoText(item));
        for (var i = 0; i < urls.length; i++) {
            const response = await fetch(mediaURL + urls[i], { timeout: 200000 });
            const blob = await response.blob();
            console.log('blob=>', blob);
            zip.file(urls[i], blob);
            if (i === urls.length - 1) {
                const zipData = await zip.generateAsync({
                    type: "blob",
                    streamFiles: true,
                });
                console.log(zipData);
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(zipData);
                link.setAttribute('download', fileName + '.zip');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    const login = getTimeSinceDate(data.item.last_login);
    if (!data.item) return null;
    return (
        <div>
            <div class="container-fluid">
                <div class="row justify-content-center align-items-center g-2">
                    <div class="col-xl-12">
                        <div class="container-fluid">
                            <div class="row justify-content-center align-items-center g-2">
                                <div class="col-xl-12">
                                    <PageHeader
                                        title={`المشارك ${data.item.name}`}
                                        links={[
                                            {
                                                name: title,
                                                path: '#'
                                            },
                                            {
                                                name: 'قائمة البحوث الجامعية',
                                                path: path
                                            }
                                        ]}
                                    />
                                </div>
                                <div class="col-xl-12">
                                    <ButtonGroups path='#' title='الرجوع' icon='fas fa-chevron-right' />
                                    <ButtonGroups path={path} title={'قائمة البحوث الجامعية'} icon='fas fa-list-alt' />
                                    <ButtonGroups title='حذف' onClick={() => drawer('delete', true)} icon="fas fa-trash-can" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12">
                        <div class="container-fluid man-content">
                            <div class="row justify-content-center align-items-start g-2">
                                <div class="col-lg-12 col-md-12">
                                    <div class="container-fluid">
                                        <div class="row justify-content-center align-items-start g-2">
                                            <div id='الأساسية' class="col-12 border-bottom">
                                                <p className='fs-22 f-bold pt-3'>معلومات المشاركة</p>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <div className='d-flex'>
                                                        <div className='man-info-name'>
                                                            <p className='text-black-50 fs-12'>صاحب المشاركة</p>
                                                            <p className='fs-20 f-bold'> {data.item.name} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>بيانات المشاركة</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الرمز التعريفي</p>
                                                                <p className='fs-16 f-bold'> {data.item.uid}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>صاحب المشاركة</p>
                                                                <p className='fs-16 f-bold'> {data.item.name}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>رقم الهاتف</p>
                                                                <p className='fs-16 f-bold'> {data.item.phone}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>البريد الألكتروني</p>
                                                                <p className='fs-16 f-bold'> {data.item.email !== null ? data.item.email : 'غير متوفر'}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الفئة</p>
                                                                <p className='fs-16 f-bold'> {itemType(data.item.type)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>بيانات الدراسية</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الجامعة</p>
                                                                <p className='fs-16 f-bold'> {data.item.university}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الكلية</p>
                                                                <p className='fs-16 f-bold'> {data.item.college}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>القسم</p>
                                                                <p className='fs-16 f-bold'> {data.item.section}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <p className='fs-18 f-bold p-2'>بيانات البحث</p>
                                                            <div class="col-12">
                                                                <p className='text-black-50 fs-12'>عنوان البحث</p>
                                                                <p className='fs-16 f-bold'> {data.item.search_title}</p>
                                                            </div>
                                                            {data.item.search_names.map((item, i) => {
                                                                return (
                                                                    <div class="col-xl-4 col-md-6 col-6">
                                                                        <p className='text-black-50 fs-12'>الباحث</p>
                                                                        <p className='fs-16 f-bold'> {item}</p>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>نبذة عن بحث</p>
                                                    <div class="container-fluid">
                                                        <div class="row g-2">
                                                            <div class="col-12">
                                                                <p className='fs-16 f-bold'> {data.item.text}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <p className='fs-18 f-bold p-2'>مرفقات البحث</p>
                                                            {data.item.files.map((item, i) => {
                                                                return (
                                                                    <div class="col-xl-4 col-md-6 col-6">
                                                                        <p className='text-black-50 fs-12'>المرفق</p>
                                                                        <p className='fs-16 f-bold pointer' onClick={() => window.open(mediaURL + item.file)}>أضغط الفتح</p>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <div>
                                                            <Button variant='' onClick={() => downloadFiles(data.item.files, data.item.name + "(" + data.item.search_title + ")", data.item)} className='edit-but fs-14'><i class="fas fa-download"></i> تنزيل الجميع</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <DeletePop
                    type='item'
                    url={`${url}${id}/`}
                    path={path}
                    show={open.delete}
                    handleClose={() => drawer('delete', false)}
                />
            </div>
        </div>
    )
}
