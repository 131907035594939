import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar, Image } from 'antd';
import { useCookies } from 'react-cookie';
import axiosInstance from '../../api/axiosInstance';
import { defaultsCover, defaultsImage, getTimeSinceDate, mediaURL } from '../../var_func/var_func';
import { Button } from 'react-bootstrap';
import { DeletePop } from '../../components/forms/PopForm';
import { HeaderProfile, PageHeader, ButtonGroups } from '../../components/global';
import { userGroup, userType } from '../../components/accounts/Setings';
import { SortArticles, SearchArticles, EditFormArticles } from '../../components/articles';
import ReactPlayer from 'react-player';
import moment from 'moment';

export function ItemsArticles() {
    const { id, name } = useParams()
    const [data, setData] = useState({
        item: '',
    })
    const [open, setOpen] = useState({
        one: false,
        two: false,
        three: false,
        password: false,
        status: false,
        delete: false,
        type: 777,
        id: 0,
        item: null
    });

    const [post, setPost] = useState({
        count: 0,
    })
    const drawer = (name, value, type) => {
        if (type && type !== 0) {
            setOpen((state) => {
                return { ...state, [name]: value, type: type }
            })
        } else {
            setOpen((state) => {
                return { ...state, [name]: value }
            })
        }
    }
    const getApiData = (name, value) => {
        setData((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    useEffect(() => {
        //get death

        async function getData() {
            await axiosInstance.get(`/articles/api/v1/articles/${id}/`)
                .then(res => {
                    getApiData('item', { ...res.data})
                });
        };
        getData();
    }, [id, post.count]);
    const login = getTimeSinceDate(data.item.last_login);
    const listType = (type) => {
        switch (type) {
            case 0: return 'المقال التحليلي'; break;
            case 1: return 'المقال الجدلي'; break;
            case 2: return 'المقال التفسيري'; break;
            case 3: return 'المقال المقارِن'; break;
            case 4: return 'المقال المشكلة والحل'; break;
            case 5: return 'المقال السبب والأثر'; break;
        }
    }
    if (!data.item) return null;
    return (
        <div>
            <div class="container-fluid">
                <div class="row justify-content-center align-items-center g-2">
                    <div class="col-xl-12">
                        <div class="container-fluid">
                            <div class="row justify-content-center align-items-center g-2">
                                <div class="col-xl-12">
                                    <PageHeader
                                        title={`المقالة`}
                                        links={[
                                            {
                                                name: 'المقالات',
                                                path: '#'
                                            },
                                            {
                                                name: 'قائمة المقالات',
                                                path: '/articles/list/'
                                            }
                                        ]}
                                    />
                                </div>
                                <div class="col-xl-12">
                                    <ButtonGroups path='#' title='الرجوع' icon='fas fa-chevron-right' />
                                    <ButtonGroups path='/articles/add/' title='إضافة مقالة' icon='fas fa-plus' />
                                    <ButtonGroups path='/articles/list/' title='قائمة المقالات' icon='fas fa-list-alt' />
                                    <ButtonGroups title='فرز البيانات' onClick={() => drawer('one', true)} icon='fas fa-sort' />
                                    <ButtonGroups title='البحث في البيانات' onClick={() => drawer('two', true)} icon='fas fa-search' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12">
                        <div class="container-fluid man-content">
                            <div class="row justify-content-center align-items-start g-2">
                                <div class="col-md-12 border-end">
                                    <div class="container-fluid">
                                        <div class="row justify-content-center align-items-start g-2">
                                            <div id='الأساسية' class="col-12">
                                                <p className='fs-22 f-bold pt-3'>المعلومات الأساسية</p>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <div className='d-flex'>
                                                        {/* <Avatar size={150} src={data.item.image !== null ? mediaURL + data.item.image : defaultsImage} /> */}
                                                        <div className='man-info-name'>
                                                            <p className='fs-20 f-bold'>العنوان : {data.item.title} </p>
                                                            <p className='fs-16 f-bold'>المدون : {data.item.author} </p>
                                                            <p className='fs-14 f-bold'>نوع المقال : {listType(data.item.type)} </p>

                                                        </div>
                                                        <div>
                                                            <Button variant='' onClick={() => drawer('delete', true)} className='delete-but fs-14'>حذف <i class="fas fa-trash-can"></i></Button>
                                                            <Button variant='' onClick={() => drawer('three', true, 777)} className='edit-but fs-14'>تعديل <i class="fas fa-pen"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>البيانات الأساسية</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>العنوان</p>
                                                                <p className='fs-16 f-bold'>{data.item.title}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>المدون</p>
                                                                <p className='fs-16 f-bold'>{data.item.author}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>نوع المقالة</p>
                                                                <p className='fs-16 f-bold'>{listType(data.item.type)}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>أولوية المقالة</p>
                                                                <p className='fs-16 f-bold'>{data.item.sort === 0 ? 'مهم' : "اعتيادي"}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الكلمات المفتاحية</p>
                                                                <p className='fs-16 f-bold'>{data.item.tags !== null ? data.item.tags.map(item => item + ' ,') : "غير متوفرة"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>الموجز</p>
                                                    <div class="m-2">
                                                        <p className='fs-16 f-bold'> {data.item.summary} </p>
                                                    </div>
                                              
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>المقالة</p>
                                                    <div class="m-2">
                                                        <p className='fs-16 f-bold'> {data.item.text} </p>
                                                    </div>
                                              
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>صورة المقالة</p>
                                                    <div class="container-fluid">
                                                        <div class="row justify-content-center align-items-center g-2">
                                                            <div class="col-12 d-flex justify-content-center align-items-center">
                                                                <Image src={mediaURL + data.item.image} width={'100%'} height={400} style={{ objectFit: 'cover' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                     
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>بيانات النشر</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>حالة المقالة</p>
                                                                <p className='fs-16 f-bold'>{data.item.is_active ? 'منشور' : 'غير منشور'}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>تاريخ نشر المقالة</p>
                                                                <p className='fs-16 f-bold'>{moment(data.item.publication_date).format("HH:mm:ss YYYY-MM-DD")}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>تاريخ رفع المقالة</p>
                                                                <p className='fs-16 f-bold'>{moment(data.item.createdAt).format("HH:mm:ss YYYY-MM-DD")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <SortArticles
                    open={open.one}
                    onClose={() => drawer('one', false)}
                />
                <SearchArticles
                    open={open.two}
                    onClose={() => drawer('two', false)}
                />
                <EditFormArticles
                    id={id}
                    item={data.item}
                    type={open.type}
                    open={open.three}
                    onClose={() => drawer('three', false)}
                    onPut={() => changePost('count', post.count + 1)}
                />
                <DeletePop
                    type='item'
                    url={`/articles/api/v1/articles/${id}/`}
                    path='/articles/list/'
                    show={open.delete}
                    handleClose={() => drawer('delete', false)}
                />
            </div>
        </div>
    )
}
