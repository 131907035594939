import React, { useEffect, useState } from 'react'
import { userId } from '../../var_func/var_func';
import axiosInstance from '../../api/axiosInstance';
import { userGroup, userType } from '../../components/accounts/Setings';
import { CustomInput } from '../../components/forms/CustomForms';
import { Input } from 'antd';
import { Button } from 'react-bootstrap';
import { AddPop, ErrorPop } from '../../components/forms/PopForm';
import { PageHeader } from '../../components/global';
export function Profile() {
    const [data, setData] = useState({
        item: '',
    })
    const [open, setOpen] = useState({
        one: false,
        two: false,
        three: false,
        password: false,
        status: false,
        delete: false,
        type: 777,
        id: 0,
        item: null
    });
    const [formPass, setFormPass] = useState({
        oldpassword: '',
        password: '',
        repassword: '',
    })
    const [formValues, setFormValues] = useState({
        name: '',
  
    })
    const [post, setPost] = useState({
        count: 0,
        progress: 0,
    })
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showOnepass: false,
        showTwopass: false,
        showError: false,
        errorMessage: '',
    })
    const change = (name, value) => {
        setFormValues((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePass = (name, value) => {
        setFormPass((state) => {
            return { ...state, [name]: value }
        })
    }
    const drawer = (name, value, type) => {
        if (type && type !== 0) {
            setOpen((state) => {
                return { ...state, [name]: value, type: type }
            })
        } else {
            setOpen((state) => {
                return { ...state, [name]: value }
            })
        }
    }
    const getApiData = (name, value) => {
        setData((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }
    useEffect(() => {
        //get death
        async function getData() {
            await axiosInstance.get(`/accounts/api/v1/users/${userId}/`)
                .then(res => {
                    getApiData('item', { ...res.data })
                });
        };
        getData();
    }, [userId, post.count]);
    const postData = async (event) => {
        event.preventDefault()
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }

        const option = {
            onUploadProgress: UploadProgress
        }

        await axiosInstance.put(`/accounts/api/v1/change_information/`, formValues, option)
            .catch(error => {
                popForm('showTwo', false)
                popForm('showError', true)
                changePost('progress', 0)
                if (error.request.status === 400) {
                    popForm('errorMessage', 'هنالك نقص في المعلومات')
                } else if (error.message === 'Network Error') {
                    popForm('errorMessage', 'لا يوجد أتصال بالانترنيت')
                }
                else if (error.request.status === 406) {
                    popForm('errorMessage', 'اسم المستخدم يجب ان يكون مكون من اربع كاركترات')
                }
            }).then(res => {
                if (res.status === 200) {
                    changePost('progress', 0)
                    popForm('showTwo', false)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
            })
    }
    const postDatapass = async (event) => {
        event.preventDefault()
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }
        const option = {
            onUploadProgress: UploadProgress
        }

        await axiosInstance.put(`/accounts/api/v1/change_password/`, formPass, option)
            .catch(error => {
                popForm('showTwopass', false)
                popForm('showError', true)
                changePost('progress', 0)
                console.log(error.request.status)
                if (error.request.status === 401) {
                    popForm('errorMessage', 'كلمة المرور الحالية غير صحيحة الرجاء اذا لم تكن تعرف كلمة المرور أتصل بمشرف النظام لتغير كلمة السر')
                } else if (error.request.status === 400) {
                    popForm('errorMessage', 'هنالك نقص في المعلومات')
                }
            }).then(res => {
                if (res.status === 200) {
                    changePost('progress', 0)
                    popForm('showTwopass', false)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
            })
    }
    if (!data.item) return null
    return (
        <div className='mt-3'>
            <div class="col-xl-12 m-2">
                <PageHeader
                    title='الملف الشخصي'
                    links={[]}
                />
            </div>
            <div class="container-fluid">
                <div class="main-body">
                    <div class="row gutters-sm">
                        <div class="col-md-4 mb-3">
                            <div class="card mb-3 h-100">
                                <div class="card-body">
                                    <div class="row ">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">الأسم الكامل</h6>
                                        </div>
                                        <div class="col-sm-9 text-secondary">
                                            {data.item.name}
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">اسم المستخدم</h6>
                                        </div>
                                        <div class="col-sm-9 text-secondary">
                                            {data.item.username}
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">الصلاحية</h6>
                                        </div>
                                        <div class="col-sm-9 text-secondary">
                                            {userType(data.item.type)}
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">نوع الصلاحية</h6>
                                        </div>
                                        <div class="col-sm-9 text-secondary">
                                            {userGroup(data.item.group_type)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="card mb-3">
                                <div class="card-body">
                                    <div class="row g-2">
                                        <div class="col-12 d-flex justify-content-between p-3 border-bottom">
                                            <h5 className='f-bold'><i class="fa-solid fa-gears" style={{ color: '#0c7676' }}></i> الأعدادات العامة</h5>
                                            <Button
                                                type='button'
                                                variant=''
                                                onClick={() => popForm('showOne', true)}
                                                className='but-all'
                                            >
                                                حفظ التعديلات
                                            </Button>
                                        </div>
                                        <div className='col-12'>
                                            <label>الإسم</label>
                                            <CustomInput type="text" icon='subtitles' defaultValue={data.item.name} name='name' placeholder="الإسم" onChange={(e) => change(e.target.name, e.target.value)} required />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="card mb-3">
                                <div class="card-body">
                                    <div class="row g-2">
                                        <div class="col-12 d-flex justify-content-between p-3 border-bottom">
                                            <h5 className='f-bold'><i class="fa-solid fa-lock" style={{ color: '#0c7676' }}></i> كلمة المرور</h5>
                                            <Button
                                                type='button'
                                                variant=''
                                                onClick={() => popForm('showOnepass', true)}
                                                className='but-all'
                                            >
                                                حفظ التعديلات
                                            </Button>
                                        </div>
                                        <div className='col-md-12 col-12'>
                                            <label className='optional'>كلمة المرور الحالية</label>
                                            <Input.Password size='large' name='oldpassword' placeholder="كلمة المرور الحالية" onChange={(e) => changePass(e.target.name, e.target.value)} required />
                                        </div>
                                        <div className='col-md-12 col-12'>
                                            <label className='optional'>كلمة المرور الجديدة</label>
                                            <Input.Password size='large' name='password' placeholder="كلمة المرور الجديدة" onChange={(e) => changePass(e.target.name, e.target.value)} required />
                                        </div>
                                        <div className='col-md-12 col-12'>
                                            <Input.Password size='large' name='repassword' placeholder="اعادة كلمة المرور" onChange={(e) => changePass(e.target.name, e.target.value)} required />
                                        </div>
                                        <div className='col-md-12 col-12'>
                                            <p className='text-black-50'>
                                                {formPass.password ?
                                                    (
                                                        formPass.password === formPass.repassword ?
                                                            'كلمة المرور متتطابقة'
                                                            :
                                                            'كلمة المرور غير متتطابقة'
                                                    )
                                                    :
                                                    ''
                                                }
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <AddPop
                            showOne={formPop.showOne}
                            showTwo={formPop.showTwo}
                            handleshow={(e) => {
                                popForm('showOne', false)
                                postData(e)
                                popForm('showTwo', true)
                            }}
                            handleCloseOne={() => popForm('showOne', false)}
                            handleCloseTwo={() => popForm('showTwo', false)}
                            post={post.progress}
                        />
                        <AddPop
                            showOne={formPop.showOnepass}
                            showTwo={formPop.showTwopass}
                            handleshow={(e) => {
                                popForm('showOnepass', false)
                                postDatapass(e)
                                popForm('showTwopass', true)
                            }}
                            handleCloseOne={() => popForm('showOnepass', false)}
                            handleCloseTwo={() => popForm('showTwopass', false)}
                            post={post.progress}
                        />
                        <ErrorPop
                            showError={formPop.showError}
                            handleCloseError={() => popForm('showError', false)}
                            errorMessage={formPop.errorMessage}
                        />
                    </div>
                </div>
            </div>
        </div>


    )
}
