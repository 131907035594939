export const treeData = [
    {
        value: 0,
        title: 'مسؤول منصة أبناء المهندس',
    },
    {
        value: 1,
        title: 'مدير  استمارات أبناء المهندس',
    },
    {
        value: 2,
        title: 'مشرف الأخبار',
    },
    {
        value: 3,
        title: 'مشرف التدوينات',
    },
    {
        value: 4,
        title: 'مشرف البث المباشر',
    },
];



export const userType = (type) => {
    switch (type) {
        case 0: return 'مسؤول منصة أبناء المهندس'
        case 1: return 'مدير استمارات أبناء المهندس'
        case 2: return 'مشرف الأخبار'
        case 3: return 'مشرف التدوينات'
        case 4: return 'مشرف البث المباشر'


    }
};
export const userGroup = (type) => {
    switch (type) {
        case 0: return 'مدير'
        case 1: return 'محرر'
        case 2: return 'مدير مستخدمين'
    }
};