import { Input, DatePicker, Select, TreeSelect } from "antd";
import moment from 'moment';
const { TextArea } = Input;
export const CustomInput = (props) => {
    const numperProps = props.type === 'number' ? { min: 1 } : null
    return (
        <Input
            size="large"
            type={props.type}
            placeholder={props.placeholder}
            suffix={<i class="material-icons icon-input">{props.icon}</i>}
            {...numperProps}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
            {...props}
            
        />
    )
}

export const CustomDatePicker = (props) => {
    return (
        <DatePicker
            size="large"
            className='date-picker col-12'
            format={'YYYY-MM-DD'}
            placeholder='YYYY-MM-DD'
            onChange={props.onChange}
            value={props.defaultValue ? moment(props.defaultValue, 'YYYY-MM-DD') : ''}
            {...props}
        />
    )

}

export const CustomTextArea = (props) => {
    return (
        <TextArea
            showCount
            maxLength={1000000000}
            rows={6}
            style={{ marginBottom: 24 }}
            prefix={<i class="material-icons icon-input">{props.icon}</i>}
            defaultValue={props.defaultValue}
            {...props}
        />

    )

}
export const CustomSelect = (props) => {
    return (
        <Select
            showSearch={true}
            size='large'
            allowClear
            optionFilterProp="children"
            className='col-12 text-center'
            suffixIcon={<i class="material-icons icon-select">{props.icon}</i>}
            placeholder="أختر"
            {...props}
        />

    )

}
export const CustomTreeSelect = (props) => {
    return (
        <TreeSelect
            treeLine={true}
            showSearch={true}
            size='large'
            allowClear
            optionFilterProp="children"
            className='col-12 text-center'
            suffixIcon={<i class="material-icons icon-select">{props.icon}</i>}
            placeholder="أختر"
            {...props}
        />

    )

}

