import React from 'react'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
export function TableSurvey({ data, page, type }) {
    const path = '/contributions/list/';
    const NameCell = ({ rowData, dataKey, ...props }) => (
        <Cell {...props}>
            <Link
                style={{ color: '#000' }}
                to={`${path}item/id/${rowData.id}/name/${rowData.name}`}
            >
                {rowData.name}
            </Link>
        </Cell>
    );

    const DateCell = ({ rowData, dataKey, ...props }) => (<Cell dir='ltr' {...props}>{moment(rowData.createdAt).format("YYYY-MM-DD HH:mm")}</Cell>);
    const CountCell = ({ rowData, dataKey, rowIndex, ...props }) => (
        <Cell {...props}>
            <Link
                style={{ color: '#000' }}
                to={`${path}item/id/${rowData.id}/name/${rowData.name}`}
            >
                {(((page ? page : 1) - 1) * 100) + (rowIndex + 1)}
            </Link>
        </Cell>
    );
    const LenCell = ({ rowData, dataKey, ...props }) => (<Cell  {...props}>{rowData[dataKey] !== null ? rowData[dataKey].length : 0}</Cell>);
    const renderLoading = () => {
        return (
            <div className=' d-flex justify-content-center align-items-start w-100 mt-5'>
                <Spin size="large" tip="جاري التحميل" />
            </div>
        );
    };

    return (
        <Table
            //loading={loading}
            virtualized
            height={720}
            width='100%'
            data={data}
            rtl={true}
            renderEmpty={() => {
                return <div className="rs-table-body-info">لاتوجد بيانات</div>;
            }}
            renderLoading={renderLoading}
            style={{ backgroundColor: '#fff' }}
        >
            <Column width={70} align="center" fixed>
                <HeaderCell>ت</HeaderCell>
                <CountCell dataKey="id" />
            </Column>
            <Column align='center' flexGrow={1} fullText resizable>
                <HeaderCell>الرمز التعريفي</HeaderCell>
                <Cell dataKey="uid" />
            </Column>
            <Column align='center' width={200} fullText fixed resizable>
                <HeaderCell>الأسم</HeaderCell>
                <NameCell dataKey="name" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>عنوان البحث</HeaderCell>
                <Cell dataKey="search_title" />
            </Column>
            <Column align='center' flexGrow={1} fullText resizable>
                <HeaderCell>رقم الهاتف</HeaderCell>
                <Cell dataKey="phone" />
            </Column>
            <Column flexGrow={1} align='center' fullText resizable>
                <HeaderCell>البريد الألكتروني</HeaderCell>
                <Cell dataKey="email" />
            </Column>
            <Column flexGrow={1} align='center' fullText resizable>
                <HeaderCell>الجامعة</HeaderCell>
                <Cell dataKey="university" />
            </Column>
            <Column flexGrow={1} align='center' fullText resizable>
                <HeaderCell>الكلية</HeaderCell>
                <Cell dataKey="college" />
            </Column>
            <Column flexGrow={1} align='center' fullText resizable>
                <HeaderCell>القسم</HeaderCell>
                <Cell dataKey="section" />
            </Column>
            <Column flexGrow={1} align='center' fullText resizable>
                <HeaderCell>عدد المشاركين</HeaderCell>
                <LenCell dataKey="search_names" />
            </Column>
            <Column flexGrow={1} align='center' fullText resizable>
                <HeaderCell>عدد المرفقات</HeaderCell>
                <LenCell dataKey="files" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>تاريخ المشاركة</HeaderCell>
                <DateCell dataKey="createdAt" />
            </Column>
        </Table>
    )
}
