import React, { useEffect } from 'react'
import { useState } from 'react'
import axiosInstance from '../../api/axiosInstance'
import { useCookies } from 'react-cookie';
import { PageHeader, ButtonGroups, PaginationPage } from '../../components/global';
import { SortAccounts, SearchAccounts, TableAccounts } from '../../components/accounts';



export function ListAccounts() {
    const [page, setPage] = useCookies('');
    const { page_accounts } = page;

    const [data, setData] = useState({
        list: [],
        listCount: 0,

    })
    const [open, setOpen] = useState({
        one: false,
        two: false,
    });

    const drawer = (name, value) => {
        setOpen((state) => {
            return { ...state, [name]: value }
        })
    }

    const getListData = (name, value) => {
        setData((state) => {
            return { ...state, [name]: value }
        })
    }
    useEffect(() => {
        //get death
        async function getData() {
            await axiosInstance.get(`/accounts/api/v1/users/?page_size=100&page=${page_accounts ? page_accounts : 1}`)
                .then(res => {
                    getListData('list', res.data.results)
                    getListData('listCount', res.data.count)
                }).catch(err => {
                    if (err.response.data.detail === 'Invalid page.') {
                        setPage('page_accounts', 1)
                    }
                })
        }
        getData()
    }, [page_accounts])
    return (
        <>
            <div class="container-fluid">
                <div class="row justify-content-center align-items-center g-2">
                    <div class="col-xl-12">
                        <div class="container-fluid">
                            <div class="row justify-content-center align-items-center g-2">
                                <div class="col-xl-12">
                                    <PageHeader
                                        title='قائمة المستخدمين'
                                        links={[
                                            {
                                                name: 'المستخدمين',
                                                path: '#'
                                            }
                                        ]}
                                    />
                                </div>
                                <div class="col-xl-12">
                                    <ButtonGroups path='#' title='الرجوع' icon='fas fa-chevron-right' />
                                    <ButtonGroups path='/accounts/add/' title='إضافة مستخدم' icon='fas fa-plus' />
                                    <ButtonGroups title='فرز البيانات' onClick={() => drawer('one', true)} icon='fas fa-sort' />
                                    <ButtonGroups title='البحث في البيانات' onClick={() => drawer('two', true)} icon='fas fa-search' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <TableAccounts
                            data={data.list}
                            page={page_accounts}
                            management
                            status
                        />
                    </div>
                    <div className="col-12">
                        <PaginationPage
                            defaultCurrent={!page_accounts ? 1 : Number(page_accounts)}
                            total={data.listCount}
                            defaultPageSize={100}
                            onChange={(e) => setPage('page_accounts', e === undefined ? '' : e)}
                        />
                    </div>
                </div>
            </div>
            <div>
                <SortAccounts
                    open={open.one}
                    onClose={() => drawer('one', false)}
                />
                <SearchAccounts
                    open={open.two}
                    onClose={() => drawer('two', false)}
                />
            </div>
        </>
    )
}