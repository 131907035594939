import React from 'react'
import { Progress } from 'antd';
import { Modal, Button } from 'react-bootstrap';
import axiosInstance from '../../api/axiosInstance';
import { useLocation, use, useNavigate } from 'react-router-dom';
export function AddPop(props) {
    return (
        <>
            <Modal
                size="md"
                centered={true}
                show={props.showOne}
                onHide={props.handleCloseOne}
                style={{ zIndex: 5000 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title> رفع البيانات</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div><i class="fa-regular fa-circle-check p-5" style={{ fontSize: 100, color: '#16FF00' }}></i></div>
                        <div className='title-modal'>
                            هل انت متأكد من رفع البيانات
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleCloseOne}>
                        ألغاء
                    </Button>
                    <Button variant="" className='but-all' desplay onClick={props.handleshow}>
                        متأكد
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                size="md"
                centered={true}
                show={props.showTwo}
                onHide={props.handleCloseTwo}
                animation={true}
                style={{ zIndex: 5000 }}

            >
                <Modal.Header>
                    <Modal.Title >جاري رفع البيانات</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column justify-content-center align-items-center '>
                        <Progress
                            type="circle"
                            strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                            }}
                            percent={props.post}
                            format={() => <div className='d-flex flex-column justify-content-center align-items-center '><i class="fa-solid fa-upload p-5" style={{ fontSize: 50, color: '#16FF00' }}></i></div>}
                        />
                        <p>{props.post}%</p>
                        <p>ملاحظة</p>
                        <p>لا تقم بعمل اعادة تحميل للصفحة انتظر نهاية العداد</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export function ErrorPop(props) {
    return (
        <>
            <Modal
                size="md"
                centered={true}
                show={props.showError}
                onHide={props.handleCloseError}
                style={{ zIndex: 5000 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>حدث خطأ ما</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div><i class="fa-solid fa-circle-exclamation p-5" style={{ fontSize: 100, color: '#f00' }}></i></div>
                        <div className='title-modal-error text-center' style={{ color: '#f00' }}>
                            {props.errorMessage}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export function DeletePop(props) {
    const location = useNavigate()
    const { url, onDelete, handleClose, type, show, path } = props;
    const Delete = async () => {
        await axiosInstance.delete(url)
            .then(res => {
                if (res.status === 204) {
                    handleClose()
                    if (type === 'item') {
                        location(path)
                    } else {
                        onDelete()
                    }
                }
            })
    }
    return (
        <Modal
            size="md"
            centered={true}
            show={show}
            onHide={handleClose}
            style={{ zIndex: 5000 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>حذف البيانات</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div>
                        <i class="fa-regular fa-trash-can text-danger p-3" style={{ fontSize: 100 }}></i>
                    </div>
                    <div className='title-modal text-center'>
                        هل انت متأكد من حذف هذه البيانات عليك التأكد ان هذه البيانات غير مرتبطة ببيانات أخرى
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    ألغاء
                </Button>
                <Button variant="" className='but-all' desplay onClick={Delete}>
                    متأكد
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export function ReportPop(props) {
    const { onOK, open, onClose, title } = props;

    return (
        <Modal
            size="md"
            centered={true}
            show={open}
            onHide={onClose}
            style={{ zIndex: 5000 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>تحميل البيانات</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div>
                        <i class="fa-regular fa-file-excel text-success p-3" style={{ fontSize: 100 }}></i>
                    </div>
                    <div className='title-modal text-center'>
                        سوف يتم تحميل البيانات الكاملة على شكل ملف مايكروسوفت أكسيل لحجوزات {title}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    ألغاء
                </Button>
                <Button variant="" className='but-all' desplay onClick={onOK}>
                    متأكد
                </Button>
            </Modal.Footer>
        </Modal>
    )
}