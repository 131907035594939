import React, { useState } from 'react'
import { CustomInput } from '../forms/CustomForms';
import axiosInstance from '../../api/axiosInstance';
import { useEffect } from 'react';
import { Button, Offcanvas, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { PaginationPage } from '../global';
import moment from 'moment';
export function SearchArticles(prpos) {
    const { onClose, open } = prpos;
    const [page, setPage] = useCookies('');
    const { search_Articles_list } = page;
    const [sortValues, setSortValues] = useState({
        name: '',
        username: '',
        management: '',
        outher: '',
    })
    const [SortData, setSortData] = useState({
        list: [],
        listCount: 0
    })

    const [drawer, setDrawer] = useState(false);
    const change = (name, value) => {
        setSortValues((state) => {
            return { ...state, [name]: value }
        })
    }
    const getFormData = (name, value) => {
        setSortData((state) => {
            return { ...state, [name]: value }
        })
    }
    const showChildrenDrawer = () => {
        setDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setDrawer(false);
    };
    useEffect(() => {
        //get death
        async function getData() {
            const search = `${sortValues.name}`;
            if (drawer) {
                await axiosInstance.get(`/articles/api/v1/articles/?search=${search}&page_size=100&page=${search_Articles_list ? search_Articles_list : 1}`)
                    .then(res => {
                        getFormData('list', res.data.results)
                        getFormData('listCount', res.data.count)
                    }).catch(err => {
                        if (err.response.data.detail === 'Invalid page.') {
                            setPage('search_Articles_list', 1)
                        }
                    })
            }

        }
        getData()
    }, [sortValues, drawer, search_Articles_list])
    const listType = (type) => {
        switch (type) {
            case 0: return 'المقال التحليلي'; break;
            case 1: return 'المقال الجدلي'; break;
            case 2: return 'المقال التفسيري'; break;
            case 3: return 'المقال المقارِن'; break;
            case 4: return 'المقال المشكلة والحل'; break;
            case 5: return 'المقال السبب والأثر'; break;
        }
    }
    return (
        <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>البحث قي قائمة المقالات</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div class="container-fluid">
                    <div className='row justify-content-center g-2'>
                        <div className='col-12' >
                            <label>بحث</label>
                            <CustomInput icon='subtitles' name='name' onChange={(e) => change(e.target.name, e.target.value)} />
                        </div>

                    </div>
                    <div className='row justify-content-center g-2 mt-3'>
                        <Button variant="" className='but-all w-100' onClick={showChildrenDrawer}>
                            معاينة النتائج
                        </Button>
                    </div>
                </div>

                <Offcanvas show={drawer} onHide={onChildrenDrawerClose} className='offcanvas-edit'>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>نتائج البحث</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='table-responsive'>
                            <Table className="align-items-center table-flush">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">عنوان الخبر</th>
                                        <th scope="col">المدون</th>
                                        <th scope="col">نوع المقالة</th>
                                        <th scope="col">أولوية المقالة</th>
                                        <th scope="col">حالة المقالة</th>
                                        <th scope="col">تاريخ النشر</th>
                                        <th scope="col">تاريخ الرفع</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {SortData.list.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td width='20%'>
                                                    <Link to={`/articles/list/id/${item.id}/name/${item.title}`}> {item.title}</Link>
                                                </td>
                                                <td width='20%'>{item.author}</td>
                                                <td width='20%'>{listType(item.type)}</td>
                                                <td width='20%'>{item.sort === 0 ? 'مهم' : "اعتيادي"}</td>
                                                <td width='20%'>{item.is_active ? 'منشور' : "غير منشور"}</td>
                                                <td dir='ltr' width={1}>{moment(item.publication_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                <td dir='ltr' width={1}>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>


                        <div className="col-12">
                            <PaginationPage
                                defaultCurrent={!search_Articles_list ? 1 : Number(search_Articles_list)}
                                total={SortData.martyrCount}
                                defaultPageSize={100}
                                onChange={(e) => setPage('search_Articles_list', e === undefined ? '' : e)}
                            />
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </Offcanvas.Body>
        </Offcanvas>
    )
}


