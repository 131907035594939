import CryptoJS from 'crypto-js'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// import { tafqeet } from '../components/tafqeet/Tafqeet';
import { Modal } from 'antd'
import * as XLSX from 'xlsx'
import { key_crypto } from '../api/axiosInstance';
//user id 
const user_id = localStorage.getItem('user_id')
const name_type = localStorage.getItem('name_type')
const group_type = localStorage.getItem('group_type')
export const userId = user_id ? JSON.parse(CryptoJS.AES.decrypt(user_id, key_crypto).toString(CryptoJS.enc.Utf8)) : '';
export const userType = name_type ? JSON.parse(CryptoJS.AES.decrypt(name_type, key_crypto).toString(CryptoJS.enc.Utf8)) : '';
export const groupType = group_type ? JSON.parse(CryptoJS.AES.decrypt(group_type, key_crypto).toString(CryptoJS.enc.Utf8)) : '';

export const defaultsImage = 'https://jcdn.eu-central-1.linodeobjects.com/shared/profile.png';
export const defaultsCover = 'https://jcdn.eu-central-1.linodeobjects.com/website/image/cov_def.png';
export const mediaURL = 'https://api.abbna.org/media/'
//export const mediaURL = 'http://localhost:3001/media/'
export function getTimeSinceDate(date) {
    const myDate = new Date(date);
    const now = new Date();
    const diff = now - myDate;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years >= 1) {
        return `${years} سنه`;
    } else if (months >= 1) {
        return `${months} شهر`;
    } else if (days >= 1) {
        return `${days} يوم`;
    } else if (hours >= 1) {
        return `${hours} ساعة`;
    } else if (minutes >= 1) {
        return `${minutes} دقيقة`;
    } else {
        return `${seconds} ثانية`;
    }
}

export function compareDates(date1, date2) {
    const timestamp1 = new Date(date1).getTime();
    const timestamp2 = new Date(date2).getTime();

    if (timestamp1 < timestamp2) {
        return true;
    } else {
        return false;
    }
}

export const PdfDocument = (id) => {
    html2canvas(document.querySelector(id)).then(canvas => {
        document.body.appendChild(canvas);  // if you want see your screenshot in body.
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 0, 0);
        pdf.save("download.pdf");
    });

}
//slice Array
export function sliceArray(array, size) {
    const res = [];
    for (let i = 0; i < array.length; i += size) {
        const chunk = array.slice(i, i + size);
        res.push(chunk);
    }
    return res;
}
//sort Array
export function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}
// export function numberToString(num) {
//     switch (num) {
//         case '1': return 'الأولى'; break;
//         case '2': return 'الثانية'; break;
//         case '3': return 'الثالثة'; break;
//         case '4': return 'الرابعة'; break;
//         case '5': return 'الخامسة'; break;
//         case '6': return 'السادسة'; break;
//         case '7': return 'السابعة'; break;
//         case '8': return 'الثامنة'; break;
//         case '9': return 'التاسعة'; break;
//         case '10': return 'العاشرة'; break;
//         default: return tafqeet(num); break;
//     }
// }
// export function numberToStringBook(num) {
//     switch (num) {
//         case '1': return 'أول'; break;
//         case '2': return 'ثاني'; break;
//         case '3': return 'ثالث'; break;
//         case '4': return 'رابع'; break;
//         case '5': return 'خامس'; break;
//         case '6': return 'سادس'; break;
//         case '7': return 'سابع'; break;
//         case '8': return 'ثامن'; break;
//         case '9': return 'تاسع'; break;
//         case '10': return 'عاشر'; break;
//         case '11': return 'أحد عشر'; break;
//         case '12': return 'أثنا عشر'; break;
//         default: return tafqeet(num); break;
//     }
// }

export function popUb(num) {
    Modal.confirm({
        title: 'قيد التطوير ',
        icon: <i class="fa-solid fa-code" style={{ fontSize: 100, color: '#aaa' }}></i>,
        onCancel() {
            console.log('Cancel');
        },

        okButtonProps: {
            hidden: true,
        },
        cancelText: ' ألغاء ',
        style: { direction: 'rtl' },
        maskClosable: true
    })
}
export function popUb2(num) {
    Modal.confirm({
        title: 'تم الفصل المشروع عن جمال1',
        icon: <svg className='conform-icon-ok' />,
        onCancel() {
            console.log('Cancel');
        },

        okButtonProps: {
            hidden: true,
        },
        cancelText: ' ألغاء ',
        style: { direction: 'rtl' },
        maskClosable: true
    })
}
export const downlodXlsx = (data, name) => {
    const xlsxFile = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(data)
    XLSX.utils.book_append_sheet(xlsxFile, ws, name);
    XLSX.writeFile(xlsxFile, `${name}.xlsx`,)
}
export const gitImageDim = (e, dim) => {
    const ev = e.currentTarget.files;
    if (ev.length === 1) {
        const image = document.createElement('img')
        image.src = URL.createObjectURL(e.target.files[0]);
        image.onload = () => {
            dim(`${image.width}x${image.height}`)
        }
    } else {
        return null;
    }
}

export function range(start, end) {
    var ans = [];
    for (let i = start; i <= end; i++) {
        ans.push(i);
    }
    return ans;
}
export const styleNav = () => {
    const navbar = document.getElementById('navbar');
    console.log(navbar.classList)
    let scrolled = false;
    document.addEventListener('scroll', () => {
        if (30 < window.pageYOffset && !scrolled) {
            navbar.classList.add('fixed-top')
            scrolled = true;
        };
        if (30 > window.pageYOffset && scrolled) {
            navbar.classList.remove('fixed-top')
            scrolled = false;
        };
    })
}


export const taglist = [
    'الحشد_الشعبي',
    'الحشد_الشعبي_المقدس',
    'العراق',
    'قادة_النصر',
    'ابو_مهدي_المهندس',
    'قاسم_سليماني',
    'الشهادة_والسيادة',
    'تنضيم_داعش',
    'معارك_التحرير',
    'الأرهاب',
    'داعش',
    'الموصل',
    'الأنبار',
    'بغداد',
    'كربلاء',
    'عاشوراء',
    'محرم_الحرام',
]


export function itemType(num) {
    switch (num) {
        case 0: return 'لاشيء'; break;
        case 1: return 'ذوي الشهداء'; break;
        case 2: return 'ذوي الجرحى'; break;
        case 3: return 'منتسب في الحشد'; break;
        default: return null; break;
    }
}