import { popUb } from "../../var_func/var_func"

export const list = [
    {
        name: 'قائمة الأستمارات ',
        path: '/contributions/list/',
        color: "#0c7676",
        key: 37,
        icon: 'fa-solid fa-list-alt',
        content: [
            {
                name: 'قائمة البحوث الجامعية',
                type: 'link',
                path: '/contributions/list/',
                color: "#19A7CE",
                icon: 'fa-solid fa-list-alt',
            },

        ]
    },

]

export const list2 = [
    {
        name: 'الأخبار',
        path: '/news/home/',
        color: "#19A7CE",
        icon: 'fas fa-rss',
        key: 32,
        content: [
            {
                name: 'إضافة خبر',
                type: 'link',
                path: '/news/add/',
                color: "#19A7CE",
                icon: 'fa-solid fa-plus',
            },
            {
                name: 'قائمة الأخبار',
                type: 'link',
                path: '/news/list/',
                color: "#19A7CE",
                icon: 'fa-solid fa-list-alt',
            },

        ]
    },

]
export const list3 = [

    {
        name: 'التدوينات',
        path: '/articles/home/',
        color: "#19A7CE",
        icon: 'fas fa-pen-fancy',
        key: 30,
        content: [
            {
                name: 'إضافة مقالة',
                type: 'link',
                path: '/articles/add/',
                color: "#19A7CE",
                icon: 'fa-solid fa-plus',
            },
            {
                name: 'قائمة المقالات',
                type: 'link',
                path: '/articles/list/',
                color: "#19A7CE",
                icon: 'fa-solid fa-list-alt',
            },

        ]
    },


]
export const list4 = [

    {
        name: 'البث المباشر',
        path: '/accounts/home/',
        color: "#19A7CE",
        icon: 'fas fa-tv',
        key: 31,
        content: [
            {
                name: 'إضافة بث',
                type: 'link',
                path: '/channels/add/',
                color: "#19A7CE",
                icon: 'fa-solid fa-plus',
            },
            {
                name: 'قائمة البث المباشر',
                type: 'link',
                path: '/channels/list/',
                color: "#19A7CE",
                icon: 'fa-solid fa-list-alt',
            },

        ]
    },

]
export const listUsers = [
    {
        name: 'المستخدمين',
        path: '/accounts/home/',
        color: "#19A7CE",
        icon: 'fas fa-users',
        key: 29,
        content: [
            // {
            //     name: 'رئيسية المستخدمين',
            //     type: 'link',
            //     path: '/accounts/home/',
            //     color: "#009FBD",
            //     icon: 'fa-solid fa-house'
            // },
            {
                name: 'إضافة مستخدم',
                type: 'link',
                path: '/accounts/add/',
                color: "#19A7CE",
                icon: 'fa-solid fa-plus',
            },
            {
                name: 'قائمة المستخدمين',
                type: 'link',
                path: '/accounts/list/',
                color: "#19A7CE",
                icon: 'fa-solid fa-list-alt',
            },

        ]
    },
    {
        name: 'الأعدادات',
        type: 'link',
        path: '/settings/',
        color: "#19A7CE",
        icon: 'fas fa-cog',
    },
]

