import React from 'react';
import CryptoJS from 'crypto-js'
import { BrowserRouter as Router, Navigate, Routes, Route } from 'react-router-dom';
import { Button, Result } from 'antd';
import Admin from './components/layouts/Admin';
import Login from './pages/Login';
import { routes } from './routes';
import { groupType, userId, userType } from './var_func/var_func';
import Home from './pages/Home';

export function AllRoute({ Auth, loading, setloading, token }) {
    const Loading = () => {
        return (
            <div className='loading'>
                <div className='back-loading heartbeat'>
                </div>
            </div>
        )
    }
    const NotFound = () => {
        return (
            <div>
                <Result
                    status="404"
                    subTitle="404"
                    title={`الصفحة الحالية غير موجوده`}
                    extra={<Button type="" className='but-all' href='/'>الذهاب الى الرئيسية</Button>}
                />
            </div>
        )
    }
    const PrivateLogin = ({ component: Compontent, auth, redirect }) => {
        return !auth ? (loading === false ? <Compontent setloading={setloading} /> : <Loading />) : <Navigate to={redirect} replace />;
    }
    const PrivateRoute = ({ component: Compontent, auth, redirect, type }) => {
        return token ? <Compontent type={type} /> : <Navigate to={auth ? null : redirect} replace />;
    }
    return (
        <Router>
            <div className="App">
                {Auth.auth ?
                    <Admin />
                    : null
                }
                <div id={Auth.auth ? 'page-content' : ''} className={Auth.auth ? 'page-container' : ''}>
                    <Routes>
                        <Route path="*" element={<NotFound />} />
                        <Route
                            exact={true} path='/login'
                            element={<PrivateLogin auth={Auth.auth} redirect={'/'} component={Login} />} />
                        <Route
                            exact={true} path='/'
                            element={<PrivateRoute auth={Auth.auth} redirect={'/login'} component={Home} />} />
                        {routes.map((item, i) => {
                            const Component = item.element;
                            const type = item.type;
                            const group = item.group;
                            const survey = item.survey;
                            if (survey) {
                                if (type.includes(Number(userType)) && group.includes(Number(groupType))) {
                                    return (
                                        <Route
                                            id={item.name}
                                            exact={true} path={item.path}
                                            element={<PrivateRoute auth={Auth.auth} type={survey} redirect={'/login'} component={Component} />} />
                                    )
                                } else {
                                    return null
                                }
                            }
                            if (type.includes(Number(userType)) && group.includes(Number(groupType))) {
                                return (
                                    <Route
                                        id={item.name}
                                        exact={true} path={item.path}
                                        element={<PrivateRoute auth={Auth.auth} redirect={'/login'} component={Component} />} />
                                )
                            } else {
                                return null
                            }
                        }
                        )
                        }
                    </Routes>
                </div>
            </div>
        </Router>
    )

}
