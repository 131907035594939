import React from 'react'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Image, Spin } from 'antd';
import { defaultsImage } from '../../var_func/var_func';
import { Link } from 'react-router-dom';
import moment from 'moment';
export function TableArticles({ data, page }) {
    const listType = (type) => {
        switch (type) {
            case 0: return 'المقال التحليلي'; break;
            case 1: return 'المقال الجدلي'; break;
            case 2: return 'المقال التفسيري'; break;
            case 3: return 'المقال المقارِن'; break;
            case 4: return 'المقال المشكلة والحل'; break;
            case 5: return 'المقال السبب والأثر'; break;
    }
}
    const NameCell = ({ rowData, dataKey, ...props }) => (
        <Cell {...props}>
            <Link
                style={{ color: '#000' }}
                to={`/articles/list/id/${rowData.id}/name/${rowData.title}`}
            >
                {rowData.title}
            </Link>
        </Cell>
    );
    const StatusCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{rowData.is_active ? 'منشور' : "غير منشور"}</Cell>);
    const TypeCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{listType(rowData.type)}</Cell>);
    const SortCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{rowData.sort === 0 ? 'مهم' : "اعتيادي"}</Cell>);
    const CountCell = ({ rowData, dataKey, rowIndex, ...props }) => (
        <Cell {...props}>
            <Link
                style={{ color: '#000' }}
                to={`/articles/list/id/${rowData.id}/name/${rowData.title}`}
            >
                {(((page ? page : 1) - 1) * 100) + (rowIndex + 1)}
            </Link>
        </Cell>
    );
    const DateCell = ({ rowData, dataKey, rowIndex, ...props }) => (
        <Cell {...props}>
            {rowData[dataKey] !== null ? moment(rowData[dataKey]).format('HH:mm:ss YYYY-MM-DD') : null}
        </Cell>
    );
    const renderLoading = () => {
        return (
            <div className=' d-flex justify-content-center align-items-start w-100 mt-5'>
                <Spin size="large" tip="جاري التحميل" />
            </div>
        );
    };

    return (
        <Table
            //loading={loading}
            virtualized
            height={720}
            width='100%'
            data={data}
            rtl={true}
            renderEmpty={() => {
                return <div className="rs-table-body-info">لاتوجد بيانات</div>;
            }}
            renderLoading={renderLoading}
            style={{ backgroundColor: '#fff', flexDirection: 'row-reverse' }}
        >
            <Column width={70} align="center" >
                <HeaderCell>ت</HeaderCell>
                <CountCell dataKey="id" />
            </Column>
            <Column align='center' flexGrow={1} width={200} fullText resizable>
                <HeaderCell>العنوان</HeaderCell>
                <NameCell dataKey="title" />
            </Column>
            <Column align='center' width={200} fullText resizable>
                <HeaderCell>المدون</HeaderCell>
                <Cell dataKey="author" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>نوع المقالة</HeaderCell>
                <TypeCell dataKey="type" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>أولوية المقالة</HeaderCell>
                <SortCell dataKey="sort" />
            </Column>
      
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>حالة المقالة</HeaderCell>
                <StatusCell dataKey="is_active" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>تاريخ النشر</HeaderCell>
                <DateCell dataKey="publication_date" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>تاريخ الرفع</HeaderCell>
                <DateCell dataKey="createdAt" />
            </Column>
        </Table>
    )
}



