import React from 'react';
import { useState } from 'react';
import { CardsForm } from '../cards/Card';
import { CustomDatePicker, CustomInput, CustomTextArea, CustomSelect, CustomTreeSelect } from '../forms/CustomForms';
import { Avatar, Checkbox, Form, Input, TreeSelect } from 'antd';
import { ImageUpload } from '../forms/ImageUpload';
import { Button, Table } from 'react-bootstrap';
import { defaultsImage, range, userId } from '../../var_func/var_func';
import axiosInstance from '../../api/axiosInstance';
import { useEffect } from 'react';
import { AddPop, ErrorPop } from '../forms/PopForm';
import { Link } from 'react-router-dom';
import { treeData, userGroup, userType } from './Setings';

export function AddFormAccounts() {
    const [form] = Form.useForm();
    // forms valus to post data
    const [formValues, setFormValues] = useState({
        username: '',
        name: '',
        password: '',
        type: 0,
        user_type: 0,
        group_type: 0,
        repassword: '',
        is_active: true
    })
    // select data in form
    const [formData, setFormData] = useState({
        list: [],
        managements: [],
    })
    // search values to select forms
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    // search values to select forms
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showError: false,
        errorMessage: '',
    })
    const [view, setView] = useState({
        image: null,
    })
    const [post, setPost] = useState({
        progress: 0,
        count: 0,
    })
    // func to change values in form
    const change = (name, value) => {
        setFormValues((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change file values in form
    const changeFile = (name, value) => {
        if (value[0]) {
            const file = URL.createObjectURL(value[0])
            setView((state) => {
                return { ...state, [name]: file }
            })
            setFormValues((state) => {
                return { ...state, [name]: value[0] }
            })
        } else {
            setView((state) => {
                return { ...state, [name]: null }
            })
            setFormValues((state) => {
                return { ...state, [name]: '' }
            })
        }
    }
    // func to get data from api and push it to state
    const getFormData = (name, value) => {
        setFormData((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to search in api and push it to state
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change values in pop form
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    // get data from api
    useEffect(() => {
        //get death
        async function getData() {
            await axiosInstance.get(`/accounts/api/v1/users/`)
                .then(res => {
                    getFormData('list', res.data.results)
                })
        }
        getData()
    }, [post.count])

    // post data to api

    const postData = async (event) => {
        event.preventDefault()

        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }
        const option = {
            // headers: {
            //     "Content-Type": "multipart/form-data"
            // },
            onUploadProgress: UploadProgress
        }
        const fileData = new FormData();
        let key;
        for (key in formValues) {
            if (key !== 'repassword') {
                fileData.append(key, formValues[key])
            }
        }
        await axiosInstance.post('/accounts/api/v1/users/', formValues, option)
            .catch(error => {
                popForm('showTwo', false)
                popForm('showError', true)
                changePost('progress', 0)
                if (error.request.status === 400) {
                    popForm('errorMessage', 'هنالك نقص في المعلومات')
                } else if (error.message === 'Network Error') {
                    popForm('errorMessage', 'لا يوجد أتصال بالانترنيت')
                }
                else if (error.request.status === 406) {
                    popForm('errorMessage', 'اسم المستخدم يجب ان يكون مكون من اربع كاركترات')
                }
            }).then(res => {
                if (res.status === 201) {
                    popForm('showTwo', false)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
            })
    }


    console.log(formValues)
    return (
        <>
            <form>
                <div className='container-fluid h-100'>
                    <div class="row justify-content-center g-2 ">
                        <div class="col-12 d-flex">
                            <CardsForm hasBody title='البيانات الأساسية'>
                                <div className='row justify-content-center g-2'>
                                    <div className='col-md-6 col-12'>
                                        <label className='optional'>الإسم</label>
                                        <CustomInput type="text" icon='subtitles' name='name' placeholder="الإسم" onChange={(e) => change(e.target.name, e.target.value)} required />
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <label className='optional'>اسم المستخدم</label>
                                        <CustomInput type="text" icon='alternate_email' name='username' placeholder="اسم المستخدم" onChange={(e) => change(e.target.name, e.target.value)} required />
                                    </div>
                           
                                    <div className='col-md-6 col-12'>
                                        <label className='optional'>الصلاحية</label>
                                        <CustomTreeSelect
                                            icon='rule'
                                            name='type'
                                            defaultValue={0}
                                            onChange={(e) => change('type', e === undefined ? '' : e)}
                                            onClear={() => change('type', '')}
                                            treeData={treeData}
                                        />
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <label className='optional'>نوع الصلاحية</label>
                                        <CustomSelect
                                            icon='rule'
                                            name='group_type'
                                            defaultValue={0}
                                            onChange={(e) => change('group_type', e === undefined ? '' : e)}
                                            onClear={() => change('group_type', '')}
                                        >
                                            <option value={0}>مدير</option>
                                            {formValues.type === 0 && <option value={2}>مدير مستخدمين</option>}
                                            {formValues.type !== 0 && <option value={1}>محرر</option>}

                                        </CustomSelect>
                                    </div>
                                    <div className='col-md-12 col-12'>
                                        <label className='optional'>كلمة المرور</label>
                                        <Input.Password size='large' name='password' placeholder="كلمة المرور" onChange={(e) => change(e.target.name, e.target.value)} required />
                                    </div>
                                    <div className='col-md-12 col-12'>
                                        <Input.Password size='large' name='repassword' placeholder="اعادة كلمة المرور" onChange={(e) => change(e.target.name, e.target.value)} required />
                                    </div>
                                    <div className='col-md-12 col-12'>
                                        <p className='text-black-50'>
                                            {formValues.password ?
                                                (
                                                    formValues.password === formValues.repassword ?
                                                        'كلمة المرور متتطابقة'
                                                        :
                                                        'كلمة المرور غير متتطابقة'
                                                )
                                                :
                                                ''
                                            }
                                        </p>
                                    </div>
                                    <div className='col-12' >
                                        <Checkbox defaultChecked={true} onChange={(e) => change('is_active', e.target.checked)}>تفعيل الحساب</Checkbox>
                                    </div>
                                    <div className='col-12 d-flex justify-content-end' >
                                        <Button
                                            type='button'
                                            variant=''
                                            onClick={() => popForm('showOne', true)}
                                            className='but-all col-xl-2 col-sm-4 col-12'
                                        >إضافة المستخدم</Button>
                                    </div>
                                </div>
                            </CardsForm>
                        </div>

                        <div className='col-12'>
                            <CardsForm hasBody title='اخر إضافة'>
                                <div className='table-responsive'>
                                    <Table className="align-items-center table-flush">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">الإسم</th>
                                                <th scope="col">اسم المستخدم</th>
                                                <th scope="col">الصلاحية</th>
                                                <th scope="col">نوع الصلاحية</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formData.list.map(item => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td width='20%'>
                                                            <Link to={`/accounts/list/id/${item.id}/name/${item.name}`}> {item.name}</Link>
                                                        </td>
                                                        <td width='20%'>{item.username}</td>
                                                        <td>{userType(item.type)}</td>
                                                        <td>{userGroup(item.group_type)}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardsForm>
                        </div>
                    </div>
                </div>
            </form>
            <AddPop
                showOne={formPop.showOne}
                showTwo={formPop.showTwo}
                handleshow={(e) => {
                    popForm('showOne', false)
                    postData(e)
                    popForm('showTwo', true)
                }}
                handleCloseOne={() => popForm('showOne', false)}
                handleCloseTwo={() => popForm('showTwo', false)}
                post={post.progress}
            />
            <ErrorPop
                showError={formPop.showError}
                handleCloseError={() => popForm('showError', false)}
                errorMessage={formPop.errorMessage}
            />
        </>
    )
}


