import React from 'react'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Image, Spin } from 'antd';
import { defaultsImage } from '../../var_func/var_func';
import { Link } from 'react-router-dom';
export function TableChannels({ data, page }) {

    const ImageCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Cell {...props} style={{ padding: 0 }}>
                <div
                    style={{
                        width: 30,
                        height: 30,
                        background: '#f5f5f5',
                        borderRadius: 6,
                        marginTop: 2,
                        overflow: 'hidden',
                        display: 'inline-block',
                    }}
                >
                    <Image preview={{ mask: false }} src={rowData.image !== null ? rowData.image : defaultsImage} width="100%" height='100%' style={{ objectFit: 'cover' }} />
                </div>
            </Cell>
        )
    };
    const NameCell = ({ rowData, dataKey, ...props }) => (
        <Cell {...props}>
            <Link
                style={{ color: '#000' }}
                to={`/channels/list/id/${rowData.id}/name/${rowData.name}`}
            >
                {rowData.name}
            </Link>
        </Cell>
    );
    const StatusCell = ({ rowData, dataKey, ...props }) => (<Cell {...props}>{rowData.is_active ? 'فعال' : "غير فعال"}</Cell>);
    const CountCell = ({ rowData, dataKey, rowIndex, ...props }) => (
        <Cell {...props}>
            <Link
                style={{ color: '#000' }}
                to={`/accounts/list/id/${rowData.id}/name/${rowData.poem_name}`}
            >
                {(((page ? page : 1) - 1) * 100) + (rowIndex + 1)}
            </Link>
        </Cell>
    );
    const DateCell = ({ rowData, dataKey, rowIndex, ...props }) => (
        <Cell {...props}>
            {rowData[dataKey] !== null ? rowData[dataKey].slice(0, 10) : null}
        </Cell>
    );
    const renderLoading = () => {
        return (
            <div className=' d-flex justify-content-center align-items-start w-100 mt-5'>
                <Spin size="large" tip="جاري التحميل" />
            </div>
        );
    };

    return (
        <Table
            //loading={loading}
            virtualized
            height={720}
            width='100%'
            data={data}
            rtl={true}
            renderEmpty={() => {
                return <div className="rs-table-body-info">لاتوجد بيانات</div>;
            }}
            renderLoading={renderLoading}
            style={{ backgroundColor: '#fff', flexDirection: 'row-reverse' }}
        >
            <Column width={70} align="center" >
                <HeaderCell>ت</HeaderCell>
                <CountCell dataKey="id" />
            </Column>
            <Column align='center' width={200} fullText resizable>
                <HeaderCell>الإسم</HeaderCell>
                <NameCell dataKey="name" />
            </Column>
            <Column align='center' flexGrow={1} fullText resizable>
                <HeaderCell>الوصف</HeaderCell>
                <Cell dataKey="description" />
            </Column>
            <Column width={200} align='center' fullText resizable>
                <HeaderCell>حالة البث</HeaderCell>
                <StatusCell dataKey="is_active" />
            </Column>
        </Table>
    )
}



