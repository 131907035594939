import { Pagination } from 'antd'
import React from 'react'

export function PaginationPage(props) {
    return (
        <div className=' d-flex justify-content-start align-items-start'>
            <Pagination
                hideOnSinglePage
                responsive
                style={{ direction: 'ltr' }}
                showSizeChanger={false}
                current={props.defaultCurrent}
                defaultCurrent={props.defaultCurrent}
                defaultPageSize={props.defaultPageSize}
                total={props.total}
                onChange={props.onChange}
            />
        </div>
    )
}
