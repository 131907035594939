import React from 'react'
import LinkSidebar from './Link'
import CollapseSlider from './Collapse'

export default function LinkItem({ list }) {
    return (
        <>
            {list.map((item, i) => {
                if (item.type === 'link') {
                    return (
                        <LinkSidebar
                            index={i + 1}
                            title={item.name}
                            icon={item.icon}
                            color={item.color}
                            path={item.path}
                            onclick={item.onclick}
                        />
                    )
                }
                return (
                    <CollapseSlider
                        index={item.key}
                        title={item.name}
                        icon={item.icon}
                        color={item.color}
                        path={item.path}
                        content={item.content}
                    />
                )

            })}
        </>
    )
}
