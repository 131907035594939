import React from 'react'
import { Collapse } from 'react-bootstrap';
import LinkSidebar from './Link';
import { NavLink, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie'
export default function CollapseSlider(props) {
    const { title, icon, color, index, path, content } = props;
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [link, setLink] = useCookies();
    const { key, value } = link;
    const onOpen = () => {
        setOpen(!open);
        setLink('key', index);
        navigate(path);
        if (Number(key) === index && value) {
            setLink('value', false);
        } else {
            setLink('value', true);
        }

    }
    const defaultValue = link ? (Number(key) === index && value ? true : false) : open;
    console.log('key', value)
    return (
        <>
            <NavLink key={index} className='sidebar-link w-100' onClick={onOpen}>
                <div className='content-link'>
                    <i className={icon + " icon-link"} style={{ color }}></i>
                    <p className='name-link'>{title}</p>
                    <p className={defaultValue ? 'chevron-link transition-chevron' : 'chevron-link'}>
                        <i class="fa-solid fa-chevron-right"></i>
                    </p>

                </div>
            </NavLink>
            <Collapse
                in={defaultValue}
                className='collapse-color'
            >
                <div className=''>
                    {content.map((item, i) => {
                        return (
                            <LinkSidebar
                                className='p-r'
                                index={i + 1}
                                title={item.name}
                                icon={item.icon}
                                color={item.color}
                                path={item.path}
                            />
                        )
                    })}
                </div>

            </Collapse >
        </>
    )
}
