import { Image } from 'antd';
import React from 'react';
import { Button } from 'react-bootstrap';
export const ImageUpload = (props) => {
    const { id, onChange, view,name } = props;
    return (
        <div class="upload-box" id={id}>
            <div
                class="js--image-preview"
                style={view ?
                    {
                        backgroundImage: `url(${view})`,
                        backgroundPosition: 'top',
                        backgroundSize: 'contain',
                    }
                    :
                    {}
                }
            > </div>
            <div class="upload-options">
                <label>
                    <input type="file" class="image-upload" name={name} onChange={onChange} accept=".png, .jpg, .jpeg" />
                </label>
            </div>
        </div>
    );
}

export const ImageUser = (props) => {
    const { onChange, view } = props;
    return (
        <div id='my-button' class="uploader-user">
            <div className="avatar-upload">
                <div className="avatar-edit">
                    <input type='file' id="avatarUpload" className="imageUpload " data-preview="avatarPreview" onChange={onChange} name="avatar" accept=".png, .jpg, .jpeg" />
                    <label for="avatarUpload"></label>
                </div>
                <div className="preview ">
                    {view !== null &&
                        <img src={view}
                            alt="image"
                            id="img"
                            style={{ backgroundColor: '#fff' }}
                            className="img-user"
                        />
                    }
                </div>
            </div>
        </div>
    );
}
