import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export function ButtonGroups(prpos) {
    const { title, icon, path, onClick } = prpos;
    const onBack = () => window.history.back();
    return (
        <ButtonGroup dir='ltr' size='sm' className='ms-2 mb-2' aria-label="Basic example">
            <Link to={path} onClick={title === 'الرجوع' ? onBack : onClick} className='but-all home-link-1'>{title}</Link>
            <Link to={path} onClick={title === 'الرجوع' ? onBack : onClick} className='but-all home-link-2'><i className={icon}></i></Link>
        </ButtonGroup>
    )
}
