import React from 'react'
import { NavLink } from 'react-router-dom'

export default function LinkSidebar(props) {
    const { title, icon, color, index, path, className, onclick } = props;
    return (
        <NavLink key={index} to={path} onClick={onclick} className='sidebar-link'>
            <div className={'content-link ' + className}>
                <i className={icon + " icon-link"} style={{ color }}></i>
                <p className='name-link'>{title}</p>
            </div>
        </NavLink>
    )
}
