
import React, { useContext, useEffect, useState } from 'react';
import CryptoJS from 'crypto-js'
import { useCookies } from 'react-cookie';
import { ConfigProvider, Result, notification } from 'antd';
import { AllRoute } from './AllRoute';
import { AuthApi, } from './Context';
import axiosInstance, { key_crypto } from './api/axiosInstance';

//#######################################################App function############################################
const App = () => {
  const [dot, setDot] = useCookies(['dot']);
  const [auth, setAuth] = useState(false)
  const [loading, setloading] = useState(false)
  const userToken = localStorage.getItem('refresh');
  const nameType = localStorage.getItem('name_type')
  const user = localStorage.getItem('user')
  const name = user ? JSON.parse(CryptoJS.AES.decrypt(user, key_crypto).toString(CryptoJS.enc.Utf8)) : '';
  const Token = userToken ? JSON.parse(CryptoJS.AES.decrypt(userToken, key_crypto).toString(CryptoJS.enc.Utf8)) : "";

  useEffect(() => {
    async function getData() {
      if (name !== '') {
        await axiosInstance.get(`/accounts/api/v1/users/?username=${name}`)
          .then(res => {
            const type = res.data.results[0].type
            const id = res.data.results[0].id
            const group = res.data.results[0].group_type
            const encryptType = CryptoJS.AES.encrypt(JSON.stringify(type), key_crypto).toString()
            const encryptid = CryptoJS.AES.encrypt(JSON.stringify(id), key_crypto).toString()
            const encryptgroup = CryptoJS.AES.encrypt(JSON.stringify(group), key_crypto).toString()
            localStorage.setItem('name_type', encryptType)
            localStorage.setItem('group_type', encryptgroup)
            localStorage.setItem('user_id', encryptid)

          })
      } else if (name) {
        return null;
      }
    }
    // getData()
  }, [name]);

  //Read local to login
  const readLocal = () => {
    if (Token) {
      setAuth(true)
    };
  }
  //Effect Read local to login
  useEffect(() => {
    readLocal()
  }, [])
  //--------------------------------route of all-------------------------------
  const Routeres = () => {
    const Auth = useContext(AuthApi)
    return (
      <AllRoute
        Auth={Auth}
        loading={loading}
        setloading={setloading}
        token={Token}
      />
    )
  }
  return (
    <ConfigProvider
      direction="rtl"
      theme={{
        token: {
          fontFamily: "RB-light"
        }
      }
      }
    >
      <div className="">
        <AuthApi.Provider value={{ auth, setAuth }} >
          <Routeres />
        </AuthApi.Provider>
      </div>
    </ConfigProvider>

  );
}
export default App;
