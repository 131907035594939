import { itemType, mediaURL } from "../../var_func/var_func"

export const customDataSurvey = (data) => {
    const Data = data.map(item => ({
        "الرمز التعريفي": item.uid,
        "الإسم": item.name,
        "رقم الهاتف": item.phone,
        "البريد الألكتروني": item.email ? item.email : "لا يوجد",
        "الفئة": itemType(item.type),
        "الجامعة": item.university,
        "الكلية": item.college,
        "القسم": item.section,
        "عنوان البحث": item.search_title,
        "الباحثين": `${item.search_names}`,
        "ملخص البحث": item.text,
        "المرفقات": `${item.files.map(ele => (' ' + mediaURL + ele.file + ' '))}`,
    })
    )
    return Data
}
/*
name
uid
text
phone
email
university
college
section
search_title
search_names
type
*/