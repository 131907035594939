import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar } from 'antd';
import { useCookies } from 'react-cookie';
import axiosInstance from '../../api/axiosInstance';
import { defaultsCover, defaultsImage, getTimeSinceDate, mediaURL } from '../../var_func/var_func';
import { Button } from 'react-bootstrap';
import { DeletePop } from '../../components/forms/PopForm';
import { HeaderProfile, PageHeader, ButtonGroups } from '../../components/global';
import { userGroup, userType } from '../../components/accounts/Setings';
import { SortChannels, SearchChannels, EditFormChannels } from '../../components/channels';
import ReactPlayer from 'react-player';

export function ItemsChannels() {
    const { id, name } = useParams()
    const [data, setData] = useState({
        item: '',
    })
    const [open, setOpen] = useState({
        one: false,
        two: false,
        three: false,
        password: false,
        status: false,
        delete: false,
        type: 777,
        id: 0,
        item: null
    });

    const [post, setPost] = useState({
        count: 0,
    })
    const drawer = (name, value, type) => {
        if (type && type !== 0) {
            setOpen((state) => {
                return { ...state, [name]: value, type: type }
            })
        } else {
            setOpen((state) => {
                return { ...state, [name]: value }
            })
        }
    }
    const getApiData = (name, value) => {
        setData((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    useEffect(() => {
        //get death

        async function getData() {
            await axiosInstance.get(`/tv/api/v1/channels/${id}/`)
                .then(res => {
                    getApiData('item', { ...res.data, url: res.data['link'] })
                });
        };
        getData();
    }, [id, post.count]);
    const login = getTimeSinceDate(data.item.last_login);
    if (!data.item) return null;
    return (
        <div>
            <div class="container-fluid">
                <div class="row justify-content-center align-items-center g-2">
                    <div class="col-xl-12">
                        <div class="container-fluid">
                            <div class="row justify-content-center align-items-center g-2">
                                <div class="col-xl-12">
                                    <PageHeader
                                        title={`بث ${data.item.name}`}
                                        links={[
                                            {
                                                name: 'البث المباشر',
                                                path: '#'
                                            },
                                            {
                                                name: 'قائمة البث المباشر',
                                                path: '/channels/list/'
                                            }
                                        ]}
                                    />
                                </div>
                                <div class="col-xl-12">
                                    <ButtonGroups path='#' title='الرجوع' icon='fas fa-chevron-right' />
                                    <ButtonGroups path='/channels/add/' title='إضافة بث' icon='fas fa-plus' />
                                    <ButtonGroups path='/channels/list/' title='قائمة البث المباشر' icon='fas fa-list-alt' />
                                    <ButtonGroups title='فرز البيانات' onClick={() => drawer('one', true)} icon='fas fa-sort' />
                                    <ButtonGroups title='البحث في البيانات' onClick={() => drawer('two', true)} icon='fas fa-search' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12">
                        <div class="container-fluid man-content">
                            <div class="row justify-content-center align-items-start g-2">
                                <div class="col-md-12 border-end">
                                    <div class="container-fluid">
                                        <div class="row justify-content-center align-items-start g-2">
                                            <div id='الأساسية' class="col-12">
                                                <p className='fs-22 f-bold pt-3'>المعلومات الأساسية</p>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <div className='d-flex'>
                                                        <Avatar size={150} src={data.item.image !== null ? mediaURL + data.item.image : defaultsImage} />
                                                        <div className='man-info-name'>
                                                            <p className='fs-20 f-bold'> {data.item.name} </p>
                                                        </div>
                                                        <div>
                                                            <Button variant='' onClick={() => drawer('delete', true)} className='delete-but fs-14'>حذف <i class="fas fa-trash-can"></i></Button>
                                                            <Button variant='' onClick={() => drawer('three', true, 777)} className='edit-but fs-14'>تعديل <i class="fas fa-pen"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>الوصف</p>
                                                    <div class="m-2">
                                                        <p className='fs-16 f-bold'> {data.item.description} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>رابط البث</p>
                                                    <div class="container-fluid">
                                                        <div class="row justify-content-center align-items-center g-2">
                                                            <div class="col-md-10 col-12 d-flex justify-content-center align-items-center">
                                                                <ReactPlayer
                                                                    url={data.item['url']}
                                                                    controls
                                                                    width={"100%"}
                                                                    height={"100%"}
                                                                    className='live-palyer w-100'
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>حالة البث</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>حالة البث</p>
                                                                <p className='fs-16 f-bold'>{data.item.is_active ? 'فعال' : 'غير فعال'}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <SortChannels
                    open={open.one}
                    onClose={() => drawer('one', false)}
                />
                <SearchChannels
                    open={open.two}
                    onClose={() => drawer('two', false)}
                />
                <EditFormChannels
                    id={id}
                    item={data.item}
                    type={open.type}
                    open={open.three}
                    onClose={() => drawer('three', false)}
                    onPut={() => changePost('count', post.count + 1)}
                />
                <DeletePop
                    type='item'
                    url={`/tv/api/v1/channels/${id}/`}
                    path='/channels/list/'
                    show={open.delete}
                    handleClose={() => drawer('delete', false)}
                />
            </div>
        </div>
    )
}
