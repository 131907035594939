import { Avatar } from 'antd';
import React, { useState } from 'react'
import { CustomInput } from '../forms/CustomForms';
import axiosInstance from '../../api/axiosInstance';
import { useEffect } from 'react';
import { Button, Offcanvas, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { defaultsImage } from '../../var_func/var_func';
import { useCookies } from 'react-cookie';
import { PaginationPage } from '../global';
import { userType } from './Setings';
export function SearchAccounts(prpos) {
    const { onClose, open } = prpos;
    const [page, setPage] = useCookies('');
    const { search_accounts_list } = page;
    const [sortValues, setSortValues] = useState({
        name: '',
        username: '',
        management: '',
        outher: '',
    })
    const [SortData, setSortData] = useState({
        list: [],
        listCount: 0
    })

    const [drawer, setDrawer] = useState(false);
    const change = (name, value) => {
        setSortValues((state) => {
            return { ...state, [name]: value }
        })
    }
    const getFormData = (name, value) => {
        setSortData((state) => {
            return { ...state, [name]: value }
        })
    }
    const showChildrenDrawer = () => {
        setDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setDrawer(false);
    };
    useEffect(() => {
        //get death
        async function getData() {
            const search = `${sortValues.name}`;
            if (drawer) {
                await axiosInstance.get(`/accounts/api/v1/users/?search=${search}&page_size=50&page=${search_accounts_list ? search_accounts_list : 1}`)
                    .then(res => {
                        getFormData('list', res.data.results)
                        getFormData('listCount', res.data.count)
                    }).catch(err => {
                        if (err.response.data.detail === 'Invalid page.') {
                            setPage('search_accounts_list', 1)
                        }
                    })
            }

        }
        getData()
    }, [sortValues, drawer, search_accounts_list])

    return (
        <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>البحث قي قائمة المستخدمين</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div class="container-fluid">
                    <div className='row justify-content-center g-2'>
                        <div className='col-12' >
                            <label>بحث</label>
                            <CustomInput icon='subtitles' name='name' onChange={(e) => change(e.target.name, e.target.value)} />
                        </div>

                    </div>
                    <div className='row justify-content-center g-2 mt-3'>
                        <Button variant="" className='but-all w-100' onClick={showChildrenDrawer}>
                            معاينة النتائج
                        </Button>
                    </div>
                </div>

                <Offcanvas show={drawer} onHide={onChildrenDrawerClose} className='offcanvas-edit'>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>نتائج البحث</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='table-responsive'>
                            <Table className="align-items-center table-flush">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">الإسم</th>
                                        <th scope="col">اسم المستخدم</th>
                                        <th scope="col">الصلاحية</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {SortData.list.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td width='20%'>
                                                    <Link to={`/accounts/list/id/${item.id}/name/${item.name}`}> {item.name}</Link>
                                                </td>
                                                <td>{item.username}</td>
                                                <td>{userType(item.type)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>


                        <div className="col-12">
                            <PaginationPage
                                defaultCurrent={!search_accounts_list ? 1 : Number(search_accounts_list)}
                                total={SortData.martyrCount}
                                defaultPageSize={100}
                                onChange={(e) => setPage('search_accounts_list', e === undefined ? '' : e)}
                            />
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </Offcanvas.Body>
        </Offcanvas>
    )
}


