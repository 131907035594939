import Home from "./pages/Home";
import Settings from "./pages/Settings";
import { AddAccounts, HomeAccounts, ItemsAccounts, ListAccounts, Profile } from "./pages/accounts";
import { AddArticles, ItemsArticles, ListArticles } from "./pages/articles";
import { AddChannels, ItemsChannels, ListChannels } from "./pages/channels";
import { AddNews } from "./pages/news/Adds";
import { ItemsNews } from "./pages/news/Items";
import { ListNews } from "./pages/news/Lists";
import { ItemsSurvey, ListSurvey } from "./pages/survey";


export const routes = [

    /*------------------
accounts router
--------------------*/
    {
        name: 'list_survey_list',
        path: '/contributions/list/',
        element: ListSurvey,
        survey: 1,
        type: [0, 1, 2, 3, 4],
        group: [0, 1],
    },
    {
        name: 'list_survey_list_item',
        path: '/contributions/list/item/id/:id/name/:name',
        element: ItemsSurvey,
        survey: 1,
        type: [0, 1, 2, 3, 4],
        group: [0, 1],
    },
    {
        name: 'channels_add',
        path: '/channels/add/',
        element: AddChannels,
        type: [0, 4],
        group: [0, 1],
    },
    {
        name: 'channels_list',
        path: '/channels/list/',
        element: ListChannels,
        type: [0, 4],
        group: [0, 1],
    },
    {
        name: 'channels_item',
        path: '/channels/list/id/:id/name/:name',
        element: ItemsChannels,
        type: [0, 4],
        group: [0, 1],
    },
    {
        name: 'news_add',
        path: '/news/add/',
        element: AddNews,
        type: [0, 2],
        group: [0, 1],
    },
    {
        name: 'news_list',
        path: '/news/list/',
        element: ListNews,
        type: [0, 2],
        group: [0, 1],
    },
    {
        name: 'news_item',
        path: '/news/list/id/:id/name/:name',
        element: ItemsNews,
        type: [0, 2],
        group: [0, 2],
    },
    {
        name: 'Articles_add',
        path: '/articles/add/',
        element: AddArticles,
        type: [0, 3],
        group: [0, 2],
    },
    {
        name: 'Articles_list',
        path: '/articles/list/',
        element: ListArticles,
        type: [0, 3],
        group: [0, 2],
    },
    {
        name: 'Articles_item',
        path: '/articles/list/id/:id/name/:name',
        element: ItemsArticles,
        type: [0, 3],
        group: [0, 2],
    },
    {
        name: 'accounts_add',
        path: '/accounts/add/',
        element: AddAccounts,
        type: [0],
        group: [0, 2],
    },

    {
        name: 'accounts_list',
        path: '/accounts/list/',
        element: ListAccounts,
        type: [0],
        group: [0, 2],
    },

    {
        name: 'accounts_item',
        path: '/accounts/list/id/:id/name/:name',
        element: ItemsAccounts,
        type: [0],
        group: [0, 2],
    },
    {
        name: 'settings',
        path: '/settings/',
        element: Settings,
        type: [0],
        group: [0],
    },
    {
        name: 'profile',
        path: '/profile/',
        element: Profile,
        type: [0, 1, 2, 3, 4],
        group: [0, 1, 2],
    },
]
