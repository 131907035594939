import React, { useState } from 'react'
import { SortNews, AddFormNews, SearchNews } from '../../components/news';
import {PageHeader,ButtonGroups} from '../../components/global';


export const AddNews = () => {
    const [open, setOpen] = useState({
        one: false,
        two: false,
    });

    const drawer = (name, value) => {
        setOpen((state) => {
            return { ...state, [name]: value }
        })
    }
    return (
        <div class="container-fluid h-100">
            <div class="row justify-content-center align-items-center g-2">
                <div class="col-xl-12">
                    <div class="container-fluid">
                        <div class="row justify-content-center align-items-center g-2">
                            <div class="col-xl-12">
                                <PageHeader
                                    title='إضافة خبر'
                                    links={[
                                        {
                                            name: 'الأخبار',
                                            path: '#'
                                        }
                                    ]}
                                />
                            </div>
                            <div class="col-xl-12">
                                <ButtonGroups path='#' title='الرجوع' icon='fas fa-chevron-right' />
                                <ButtonGroups path='/news/list/' title='قائمة الأخبار' icon='fas fa-list-alt' />
                                <ButtonGroups title='فرز البيانات' onClick={() => drawer('one', true)} icon='fas fa-sort' />
                                <ButtonGroups title='البحث في البيانات' onClick={() => drawer('two', true)} icon='fas fa-search' />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 h-100">
                    <AddFormNews />
                </div>
                <div>
                    <SortNews
                        open={open.one}
                        onClose={() => drawer('one', false)}
                    />
                    <SearchNews
                        open={open.two}
                        onClose={() => drawer('two', false)}
                    />
                </div>
            </div>
        </div>
    );
};