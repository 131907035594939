import { Avatar, Drawer } from 'antd';
import React, { useState } from 'react'
import { CustomInput, CustomSelect } from '../forms/CustomForms';
import axiosInstance from '../../api/axiosInstance';
import { useEffect } from 'react';
import { Button, Offcanvas, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { defaultsImage } from '../../var_func/var_func';
import { useCookies } from 'react-cookie';
import { PaginationPage } from '../global';

export function SortChannels(prpos) {
    const { onClose, open } = prpos;
    const [page, setPage] = useCookies('');
    const { sort_channels_list } = page;
    const [sortValues, setSortValues] = useState({
        first_name: '',
        last_name: '',
        type: '',
        management: '',
        is_active: '',
    })
    const [SortData, setSortData] = useState({
        managements: [],
        list: [],
        listCount: 0
    })
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    const [drawer, setDrawer] = useState(false);
    const change = (name, value) => {
        setSortValues((state) => {
            return { ...state, [name]: value }
        })
    }
    const getFormData = (name, value) => {
        setSortData((state) => {
            return { ...state, [name]: value }
        })
    }
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    const showChildrenDrawer = () => {
        setDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setDrawer(false);
    };
    useEffect(() => {
        //get death
        async function getData() {
            const sort = `is_active=${sortValues.is_active}`;
            if (drawer) {
                await axiosInstance.get(`/tv/api/v1/channels/?${sort}&page_size=100&page=${sort_channels_list ? sort_channels_list : 1}`)
                    .then(res => {
                        getFormData('list', res.data.results)
                        getFormData('listCount', res.data.count)
                    }).catch(err => {
                        if (err.response.data.detail === 'Invalid page.') {
                            setPage('sort_channels_list', 1)
                        }
                    })
            }

        }
        getData()
    }, [sortValues, drawer, sort_channels_list])


    const userType = (type) => {
        switch (type) {
            case 0: return 'مدير مشروع معراج'
            case 1: return 'مدير رحلة امرلي'
            case 2: return 'مدير رحلة بيجي'

        }
    };
    return (
        <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>فرز البث المباشر</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div class="container-fluid">
                    <div className='row justify-content-center g-2'>
                        <div className='col-12'>
                            <label>حالة البث</label>
                            <CustomSelect
                                icon='done'
                                name='is_active'
                                onChange={(e) => change('is_active', e === undefined ? '' : e)}
                                onClear={() => change('is_active', '')}
                            >
                                <option value={true}>فعال</option>
                                <option value={false}>غير مفعل</option>
                            </CustomSelect>
                        </div>

                    </div>
                    <div className='row justify-content-center g-2 mt-5 '>
                        <Button variant="" className='but-all w-100' onClick={showChildrenDrawer}>
                            معاينة النتائج
                        </Button>
                    </div>
                </div>

                <Offcanvas show={drawer} onHide={onChildrenDrawerClose} className='offcanvas-edit'>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>نتائج الفرز</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className='table-responsive'>
                            <Table className="align-items-center table-flush">

                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">الإسم</th>
                                        <th scope="col">الوصف</th>
                                        <th scope="col">حالة البث</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {SortData.list.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td width='20%'>
                                                    <Link to={`/channels/list/id/${item.id}/name/${item.name}`}> {item.name}</Link>
                                                </td>
                                                <td width='20%'>{item.description}</td>
                                                <td width='20%'>{item.is_active ? 'فعال' : "غير فعال"}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>


                        <div className="col-12">
                            <PaginationPage
                                defaultCurrent={!sort_channels_list ? 1 : Number(sort_channels_list)}
                                total={SortData.listCount}
                                defaultPageSize={100}
                                onChange={(e) => setPage('sort_channels_list', e === undefined ? '' : e)}
                            />
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </Offcanvas.Body>
        </Offcanvas>
    )
}


