import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Avatar } from 'antd';
import { useCookies } from 'react-cookie';
import axiosInstance from '../../api/axiosInstance';
import { defaultsCover, defaultsImage, getTimeSinceDate } from '../../var_func/var_func';
import { Button } from 'react-bootstrap';
import { DeletePop } from '../../components/forms/PopForm';
import { HeaderProfile, PageHeader, PaginationPage, ButtonGroups } from '../../components/global';
import { userGroup, userType } from '../../components/accounts/Setings';
import { SortAccounts, SearchAccounts, EditFormAccounts, TableAccounts } from '../../components/accounts';
import { EditPasswordAccounts, EditStatusAccounts } from '../../components/accounts/Edit';



export function ItemsAccounts() {
    const { id, name } = useParams()
    const [data, setData] = useState({
        item: '',
    })
    const [open, setOpen] = useState({
        one: false,
        two: false,
        three: false,
        password: false,
        status: false,
        delete: false,
        type: 777,
        id: 0,
        item: null
    });

    const [post, setPost] = useState({
        count: 0,
    })
    const drawer = (name, value, type) => {
        if (type && type !== 0) {
            setOpen((state) => {
                return { ...state, [name]: value, type: type }
            })
        } else {
            setOpen((state) => {
                return { ...state, [name]: value }
            })
        }
    }
    const getApiData = (name, value) => {
        setData((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    useEffect(() => {
        //get death

        async function getData() {
            await axiosInstance.get(`/accounts/api/v1/users/${id}/`)
                .then(res => {
                    getApiData('item', { ...res.data })
                });

        };
        getData();
    }, [id, post.count]);
    const login = getTimeSinceDate(data.item.last_login);
    if (!data.item) return null;
    return (
        <div>
            <div class="container-fluid">
                <div class="row justify-content-center align-items-center g-2">
                    <div class="col-xl-12">
                        <div class="container-fluid">
                            <div class="row justify-content-center align-items-center g-2">
                                <div class="col-xl-12">
                                    <PageHeader
                                        title={`المستخدم ${data.item.name}`}
                                        links={[
                                            {
                                                name: 'المستخدمين',
                                                path: '#'
                                            },
                                            {
                                                name: 'قائمة المستخدمين',
                                                path: '/accounts/list/'
                                            }
                                        ]}
                                    />
                                </div>
                                <div class="col-xl-12">
                                    <ButtonGroups path='#' title='الرجوع' icon='fas fa-chevron-right' />
                                    <ButtonGroups path='/accounts/add/' title='إضافة مستخدم' icon='fas fa-plus' />
                                    <ButtonGroups path='/accounts/list/' title='قائمة المستخدمين' icon='fas fa-list-alt' />
                                    <ButtonGroups title='فرز البيانات' onClick={() => drawer('one', true)} icon='fas fa-sort' />
                                    <ButtonGroups title='البحث في البيانات' onClick={() => drawer('two', true)} icon='fas fa-search' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12">
                        <div class="container-fluid man-content">
                            <div class="row justify-content-center align-items-start g-2">
                                <div class="col">
                                    <div className='man-link'>
                                        <a href='#الأساسية'>المعلومات الأساسية</a>
                                        <a href='#' onClick={() => drawer('password', true)}>تغير كلمة المرور </a>
                                        <a href='#' onClick={() => drawer('status', true)}>{data.item.is_active ? 'تعطيل الحساب' : 'تفعيل الحساب'}</a>
                                        <a href='#' className='delete-link' onClick={() => drawer('delete', true)}>حذف الحساب</a>
                                    </div>
                                </div>
                                <div class="col-lg-10 col-md-12 border-end">
                                    <div class="container-fluid">
                                        <div class="row justify-content-center align-items-start g-2">
                                            <div id='الأساسية' class="col-12 border-bottom">
                                                <p className='fs-22 f-bold pt-3'>المعلومات الأساسية</p>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <div className='d-flex'>
                                                        <div className='man-info-name'>
                                                            <p className='fs-20 f-bold'> {data.item.name} </p>
                                                            <p className='text-black-50 fs-15 text-end' dir='ltr'>@{data.item.username} </p>
                                                        </div>
                                                        <div>
                                                            <Button variant='' onClick={() => drawer('three', true, 777)} className='edit-but fs-14'>تعديل <i class="fas fa-pen"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>بيانات الحساب</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الإسم</p>
                                                                <p className='fs-16 f-bold'> {data.item.name} </p>

                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>اسم المستخدم</p>
                                                                <p className='fs-16 f-bold'> {data.item.username}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Button variant='' onClick={() => drawer('three', true, 1)} className='edit-but fs-14'>تعديل <i class="fas fa-pen"></i></Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>حالة الحساب</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>وضع الحساب</p>
                                                                <p className='fs-16 f-bold'>{data.item.is_active ? 'فعال' : 'معطل'}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Button variant='' onClick={() => drawer('status', true)} className='edit-but fs-14'>تعديل <i class="fas fa-pen"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='الأدارية' class="col-12 border-bottom">
                                                <p className='fs-22 f-bold pt-3'>معلومات الأدارية</p>
                                            </div>
                                            <div class="col-12">
                                                <div className='man-card-item'>
                                                    <p className='fs-18 f-bold p-2'>البيانات الأدارية</p>
                                                    <div class="container">
                                                        <div class="row g-2">
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>الصلاحية</p>
                                                                <p className='fs-16 f-bold'>{userType(data.item.type)}</p>
                                                            </div>
                                                            <div class="col-xl-4 col-md-6 col-6">
                                                                <p className='text-black-50 fs-12'>نوع الصلاحية</p>
                                                                <p className='fs-16 f-bold'>{userGroup(data.item.group_type)}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Button variant='' onClick={() => drawer('three', true, 3)} className='edit-but fs-14'>تعديل <i class="fas fa-pen"></i></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <SortAccounts
                    open={open.one}
                    onClose={() => drawer('one', false)}
                />
                <SearchAccounts
                    open={open.two}
                    onClose={() => drawer('two', false)}
                />
                <EditFormAccounts
                    id={id}
                    item={data.item}
                    type={open.type}
                    open={open.three}
                    onClose={() => drawer('three', false)}
                    onPut={() => changePost('count', post.count + 1)}
                />
                < EditPasswordAccounts
                    id={id}
                    item={data.item}
                    open={open.password}
                    onClose={() => drawer('password', false)}
                    onPut={() => changePost('count', post.count + 1)}
                />
                <EditStatusAccounts
                    id={id}
                    is_active={data.item.is_active}
                    open={open.status}
                    onClose={() => drawer('status', false)}
                    onPut={() => changePost('count', post.count + 1)}
                />

                <DeletePop
                    type='item'
                    url={`/accounts/api/v1/users/${id}/`}
                    path='/Accounts/list/'
                    show={open.delete}
                    handleClose={() => drawer('delete', false)}
                />
            </div>
        </div>
    )
}
