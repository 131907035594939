import React, { useEffect, useState } from 'react'
import { Card, Nav, ProgressBar, Row, Table } from 'react-bootstrap'
import CardHeader from '../components/cards/CardHeader';
import Cards from '../components/cards/Card';
import BarChart from '../components/charts/BarChart';
import LineChart from '../components/charts/LineChart';
import axiosInstance from '../api/axiosInstance';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function Home() {
  const [loading, setLoading] = useState(true)
  const [countNews, setcountNews] = useState(0)
  const [countArtcles, setcountArtcles] = useState(0)
  const [countTv, setcountTv] = useState(0)
  const [countContributions, setcountContributions] = useState(0)
  const [listNews, setlistNews] = useState([])
  const [listContributions, setlistContributions] = useState([])
  const [listArtcles, setlistArtcles] = useState([])
  useEffect(() => {
    async function getData() {
      await axiosInstance.get('/news/api/v1/news/')
        .then(res => {
          setcountNews(res.data.count)
          setlistNews(res.data.results)
          if (!res.ok) { setLoading(false) }
        })
      await axiosInstance.get('/articles/api/v1/articles/')
        .then(res => {
          setcountArtcles(res.data.count)
          setlistArtcles(res.data.results)
          if (!res.ok) { setLoading(false) }
        })
      await axiosInstance.get('/contributions/api/v1/contributions/')
        .then(res => {
          setcountContributions(res.data.count)
          setlistContributions(res.data.results)
          if (!res.ok) { setLoading(false) }
        })
      await axiosInstance.get('/tv/api/v1/channels/')
        .then(res => {
          setcountTv(res.data.count);
          if (!res.ok) { setLoading(false) };
        })

    }
    getData()
  }, []);
  const listType = (type) => {
    switch (type) {
      case 0: return 'المقال التحليلي'; break;
      case 1: return 'المقال الجدلي'; break;
      case 2: return 'المقال التفسيري'; break;
      case 3: return 'المقال المقارِن'; break;
      case 4: return 'المقال المشكلة والحل'; break;
      case 5: return 'المقال السبب والأثر'; break;
    }
  }
  return (
    <div className='mt-5'>
      <div class="container-fluid">
        <div class="row justify-content-center align-items-center g-2">
          <div class="col-lg-12">
            <div class="container-fluid">
              <div class="row justify-content-center align-items-center g-2">
                <div class="col-lg-3">
                  <CardHeader
                    icon='fas fa-pen'
                    title='الأستمارات'
                    count={countContributions}
                    text='أجمالي العدد الكلي للمشاركين'
                  />
                </div>
                <div class="col-lg-3">
                  <CardHeader
                    icon='fas fa-rss'
                    title='الأخبار'
                    count={countNews}
                    value=""
                    text='أجمالي العدد الكلي للأخبار في المنصة'
                  />
                </div>
                <div class="col-lg-3">
                  <CardHeader
                    icon='fa-solid fa-pen-fancy'
                    title='المقالات'
                    count={countArtcles}
                    text='أجمالي العدد الكلي للمقالات في المنصة'
                  />
                </div>
                <div class="col-lg-3">
                  <CardHeader
                    icon='fa-solid fa-tv'
                    title='البث المباشر'
                    count={countTv}
                    value=""
                    text='أجمالي العدد الكلي للقنوات في المنصة'
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="container-fluid">
              <div class="row justify-content-start align-items-start g-2">
                <div class="col-md-12">
                  <Cards subtitle='أستمارات' title='البحوث الجامعية' hasButton path='/contributions/list/' >
                    <div className='table-responsive'>
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">المشارك</th>
                            <th scope="col">عنوان البحث</th>
                            <th scope="col">الجامعة</th>
                            <th scope="col">الكلية</th>
                            <th scope="col">القسم</th>
                            <th scope="col">تاريخ المشاركة</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listContributions.map((item, index) => {
                            return (
                              <tr key={item.id}>
                                <th scope="row">
                                  <Link to={`/contributions/list/item/id/${item.id}/name/${item.name}`}>
                                    {item.name}
                                  </Link>
                                </th>
                                <td>{item.search_title}</td>
                                <td>{item.university}</td>
                                <td>{item.college}</td>
                                <td>{item.section}</td>
                                <td dir='ltr' width={1}>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Cards>
                </div>
                <div class="col-md-12">
                  <Cards title='الأخبار' hasButton path='/news/list/' >
                    <div className='table-responsive'>
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">عنوان الخبر</th>
                            <th scope="col">الوكالة</th>
                            <th scope="col">نوع الخبر</th>
                            <th scope="col">أولوية الخبر</th>
                            <th scope="col">حالة الخبر</th>
                            <th scope="col">تاريخ النشر</th>
                            <th scope="col">تاريخ الرفع</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listNews.map((item, index) => {
                            return (
                              <tr key={item.id}>
                                <th width='20%'>
                                  <Link to={`/news/list/id/${item.id}/name/${item.title}`}> {item.title}</Link>
                                </th>
                                <td width='20%'>{item.author}</td>
                                <td width='20%'>{item.type === 0 ? 'عاجل' : "خبر اعتيادي"}</td>
                                <td width='20%'>{item.sort === 0 ? 'مهم' : "اعتيادي"}</td>
                                <td width='20%'>{item.is_active ? 'منشور' : "غير منشور"}</td>
                                <td dir='ltr' width={1}>{moment(item.publication_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                <td dir='ltr' width={1}>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Cards>
                </div>
                <div class="col-md-12">
                  <Cards title='المقالات' hasButton path='/articles/list/'>
                    <div className='table-responsive'>
                      <Table className="align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">عنوان المقالة</th>
                            <th scope="col">المدون</th>
                            <th scope="col">نوع المقالة</th>
                            <th scope="col">أولوية المقالة</th>
                            <th scope="col">حالة المقالة</th>
                            <th scope="col">تاريخ النشر</th>
                            <th scope="col">تاريخ الرفع</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listArtcles.map((item, index) => {
                            return (
                              <tr key={item.id}>
                                <th width='20%'>
                                  <Link to={`/articles/list/id/${item.id}/name/${item.title}`}> {item.title}</Link>
                                </th>
                                <td width='20%'>{item.author}</td>
                                <td width='20%'>{listType(item.type)}</td>
                                <td width='20%'>{item.sort === 0 ? 'مهم' : "اعتيادي"}</td>
                                <td width='20%'>{item.is_active ? 'منشور' : "غير منشور"}</td>
                                <td dir='ltr' width={1}>{moment(item.publication_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                <td dir='ltr' width={1}>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Cards>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
