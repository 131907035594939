import React from 'react'
import { Link } from 'react-router-dom'

export function PageHeader(props) {
    const { title, links } = props;
    return (
        <div>
            <div className='page-header'>
                <Link to='/' className='page-header-link'><i className='fas fa-home'></i></Link>
                {links.map((item, index) => <Link key={index + 1} to={item.path} className='page-header-link'>{item.name}</Link>)}
                <p className='fs-18 text-light f-bold p-0 m-0'>{title}</p>
            </div>
        </div>

    )
}
