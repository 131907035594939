import { Input, Popover } from 'antd'
import Avatar from 'antd/es/avatar/avatar'
import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar as BNavbar } from 'react-bootstrap'
import CryptoJS from 'crypto-js';
import axiosInstance, { key_crypto } from '../../api/axiosInstance';
import { userId } from '../../var_func/var_func';
import { Link } from 'react-router-dom';

export default function Navbar({ onClick, open }) {
  const user = localStorage.getItem('user')
  const userImage = localStorage.getItem('name_image')
  const name_type = localStorage.getItem('name_type')
  const Type = name_type ? JSON.parse(CryptoJS.AES.decrypt(name_type, key_crypto).toString(CryptoJS.enc.Utf8)) : "";
  const name = user ? JSON.parse(CryptoJS.AES.decrypt(user, key_crypto).toString(CryptoJS.enc.Utf8)) : '';

  const loguot = () => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = '/'
  }
  const content = (
    <div
      style={{
        marginLeft: 10,
        direction: 'rtl',
        textAlign: 'right'
      }}
    >
      <p className='fs-15'><Link to='/profile/' ><i class="fa-solid fa-user text-info"></i> الملف الشخصي </Link></p>
      <p className='fs-15'><a><i class="fa-solid fa-cog text-info"></i> الأعدادات</a></p>
      <hr />
      <p className='fs-15'><a onClick={loguot}><i class="fa-solid fa-right-from-bracket text-danger"></i> تسجيل الخروج</a></p>
    </div>
  );
  return (
    <div >
      <BNavbar
        id='navbar'
        className='admin-navbar'
      >
        <Container fluid>
          <BNavbar.Brand href="#" className='nav-text' onClick={onClick}><i class="fa-solid fa-bars"></i></BNavbar.Brand>
          <BNavbar.Brand as={Link} to="/" className='nav-text'>الرئيسية</BNavbar.Brand>
          <BNavbar.Toggle aria-controls="basic-navbar-nav" />
          <BNavbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* <div>
                <Input
                  className='search-navbar'
                  placeholder="البحث"
                  prefix={<i class="fa-solid fa-magnifying-glass"></i>}
                />
              </div> */}
              <Popover
                content={content}
                placement='bottomLeft'
                style={{
                  width: 150,
                  marginLeft: 10,
                  direction: 'rtl',
                  textAlign: 'right'
                }}
                title={<p className='text-center m-0 '>اعدادات المستخدم</p>}
                trigger="click"
              >
                <Nav.Link href="#">
                  <div className='d-flex justify-content-center align-items-center'>
                    <Avatar size={30} icon={<i class="fa-solid fa-user"></i>} />
                    <p className='m-1 p-0 fs-14 nav-text'>{name}</p>
                  </div>
                </Nav.Link>
              </Popover>
            </Nav>
          </BNavbar.Collapse>
        </Container>
      </BNavbar>
    </div>
  )
}
