import React, { useState, useEffect } from 'react';
import { CustomDatePicker, CustomInput, CustomSelect, CustomTextArea, CustomTreeSelect } from '../forms/CustomForms';
import { Checkbox, Form, Input } from 'antd';
import { ImageUpload } from '../forms/ImageUpload';
import { Button, Modal, Offcanvas } from 'react-bootstrap';
import axiosInstance from '../../api/axiosInstance';
import { AddPop, ErrorPop } from '../forms/PopForm';
import { mediaURL } from '../../var_func/var_func';
import moment from 'moment';

export function EditFormArticles({ item, id, open, onClose, type, onPut }) {
    const [form] = Form.useForm();
    // forms valus to post data
    const [formValues, setFormValues] = useState(item)
    // select data in form
    const [formData, setFormData] = useState({
        managements: [],
    })
    // search values to select forms
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    // search values to select forms
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showError: false,
        errorMessage: '',
    })
    const [view, setView] = useState({
        image: item !== null ? mediaURL + item.image : null,
    })
    const [post, setPost] = useState({
        progress: 0,
    })
    // func to change values in form
    const change = (name, value) => {
        setFormValues((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change file values in form
    const changeFile = (name, value) => {
        if (value[0]) {
            const file = URL.createObjectURL(value[0])
            setView((state) => {
                return { ...state, [name]: file }
            })
            setFormValues((state) => {
                return { ...state, [name]: value[0] }
            })
        } else {
            setView((state) => {
                return { ...state, [name]: null }
            })
            setFormValues((state) => {
                return { ...state, [name]: '' }
            })
        }
    }
    // func to get data from api and push it to state
    const getFormData = (name, value) => {
        setFormData((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to search in api and push it to state
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change values in pop form
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    // get data from api


    // pust data to api

    const postData = async (event) => {
        event.preventDefault()
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }
        const option = {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: UploadProgress
        }
        const fileData = new FormData();
        let ignored = ['id', 'createdAt', "updatedAt", 'tags'];
        let nullFields = ['video_link', 'summary', "publication_date", 'text'];
        for (let key in formValues) {
            if (ignored.includes(key)) {
                continue;
            }
            else if (nullFields.includes(key) && formValues[key] === null) {
                continue;
            }
            else {
                fileData.append(key, formValues[key])
            }
        }
        if (formValues.tags !== null) formValues.tags.forEach(item => fileData.append('tags', item))

        await axiosInstance.put(`/articles/api/v1/articles/${id}/`, fileData, option)
            .catch(error => {
                popForm('showTwo', false)
                popForm('showError', true)
                changePost('progress', 0)
                if (error.request.status === 400) {
                    popForm('errorMessage', 'هنالك نقص في المعلومات')
                } else if (error.message === 'Network Error') {
                    popForm('errorMessage', 'لا يوجد أتصال بالانترنيت')
                }
                else if (error.request.status === 406) {
                    popForm('errorMessage', 'اسم المستخدم يجب ان يكون مكون من اربع كاركترات')
                }
            }).then(res => {
                if (res.status === 200) {
                    onPut()
                    changePost('progress', 0)
                    popForm('showTwo', false)
                    onClose()
                }
            })
    }
    const onFinash = (values) => {
        const value = {}
        for (let key in values) {
            if (key === 'image') {
                continue;
            }
            else if (key === 'publication_date' && values[key] === undefined) {
                continue;
            }
            else if (key === 'tags' && values[key] === undefined) {
                continue;
            } else {
                value[key] = values[key]
            }
        }
        // console.log(value)
        setFormValues((state) => {
            return { ...state, ...value }
        })
        popForm('showOne', true)
    }
    let init = {}
    for (let key in item) {
        if (key === 'image') {
            continue;
        }
        else if (key === 'publication_date' && item[key] === null) {
            continue;
        }
        else if (key === 'tags' && item[key] === null) {
            continue;
        }
        else if (key === 'publication_date' && item[key] !== null) {
            init[key] = moment(item[key]);
        }
        else {
            init[key] = item[key];
        }
    }
    console.log(formValues)
    if (!item) return null
    return (
        <>
            <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>تعديل</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form form={form} initialValues={init} onFinish={(values) => onFinash(values)}>
                        <div className='container-fluid'>
                            <div class="row justify-content-center g-2">
                                {type === 777 || type === 1 ?
                                    <>
                                        <div className='col-12'>
                                            <label>عنوان المقالة</label>
                                            <Form.Item
                                                name='title'
                                                className='my-0 py-0'
                                                rules={[
                                                    {
                                                        required: false,
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <CustomInput type="text" icon='subtitles' name='title' placeholder="عنوان الخبر" />
                                            </Form.Item>
                                        </div>
                                        <div className='col-12'>
                                            <label >المدون</label>
                                            <Form.Item
                                                name='author'
                                                className='my-0 py-0'
                                                rules={[
                                                    {
                                                        required: false,
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <CustomInput type="text" icon='newspaper' name='author' placeholder="المدون" />
                                            </Form.Item>
                                        </div>
                                        <div className='col-12'>
                                            <label>نوع المقالة</label>
                                            <Form.Item
                                                name='type'
                                                className='my-0 py-0'
                                                rules={[
                                                    {
                                                        required: false,
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <CustomSelect
                                                    icon='grade'
                                                    name='type'
                                                >
                                                    <option value={0}>المقال التحليلي</option>
                                                    <option value={1}>المقال الجدلي</option>
                                                    <option value={2}>المقال التفسيري </option>
                                                    <option value={3}>المقال المقارِن</option>
                                                    <option value={4}>المقال المشكلة والحل </option>
                                                    <option value={5}>المقال السبب والأثر</option>

                                                </CustomSelect>
                                            </Form.Item>
                                        </div>
                                        <div className='col-12'>
                                            <label>أولوية المقالة</label>
                                            <Form.Item
                                                name='sort'
                                                className='my-0 py-0'
                                                rules={[
                                                    {
                                                        required: false,
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <CustomSelect
                                                    icon='sort'
                                                    name='sort'
                                                >
                                                    <option value={0}>مهم</option>
                                                    <option value={1}>اعتيادي</option>

                                                </CustomSelect>
                                            </Form.Item>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                 
                                {type === 777 || type === 1 ?
                                    <>
                                        <div className='col-12'>
                                            <label >الكلمات المفتاحية</label>
                                            <Form.Item
                                                name='tags'
                                                className='my-0 py-0'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'يجب أن تحتوي على كلمتين على الأقل',
                                                        type: 'array',
                                                        min: 2,
                                                    },
                                                ]}
                                                hasFeedback
                                                extra='عدد الكلمات يجب ان يكون اكثر من كلمة واحدة'
                                            >
                                                <CustomSelect
                                                    icon='tags'
                                                    name='tags'
                                                    mode='tags'
                                                    placeholder="اختر او اكتب الكلمة"
                                                >
                                                    <option value={'أبناء_المهندس'}>أبناء_المهندس</option>
                                                    <option value={'تجمع_المهندس'}>تجمع_المهندس</option>

                                                </CustomSelect>
                                            </Form.Item>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                {type === 777 || type === 2 ?
                                    <>
                                        <div className='col-12'>
                                            <label >الموجز</label>
                                            <Form.Item
                                                name='summary'
                                                className='my-0 py-0'
                                                rules={[
                                                    {
                                                        required: false,
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <CustomTextArea icon='description' rows={5} name='summary' placeholder="الموجز" />
                                            </Form.Item>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                {type === 777 || type === 3 ?
                                    <>
                                        <div className='col-12'>
                                            <label >المقالة</label>
                                            <Form.Item
                                                name='text'
                                                className='my-0 py-0'
                                                rules={[
                                                    {
                                                        required: false,
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <CustomTextArea icon='description' rows={5} name='text' placeholder="المقالة" />
                                            </Form.Item>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                {type === 777 || type === 6 ?
                                    <>
                                        <div className='col-12'>
                                            <label>تاريخ النشر</label>
                                            <Form.Item
                                                name='publication_date'
                                                className='my-0 py-0'
                                                rules={[
                                                    {
                                                        required: false,
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <CustomDatePicker format="YYYY-MM-DD HH:mm:ss" showTime name='video_link' placeholder="HH:mm:ss YYYY-MM-DD" />
                                            </Form.Item>
                                        </div>
                                        <div className='col-12'>
                                            <label >حالة الخبر</label>
                                            <Form.Item
                                                name='is_active'
                                                className='my-0 py-0'
                                                rules={[
                                                    {
                                                        required: false,
                                                    },
                                                ]}
                                            >
                                                <CustomSelect
                                                    icon='done'
                                                    name='is_active'
                                                >
                                                    <option value={true}>منشور</option>
                                                    <option value={false}>غير منشور</option>
                                                </CustomSelect>
                                            </Form.Item>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                {type === 777 || type === 4 ?
                                    <>

                                        <div className='col-12'>
                                            <label >الصورة</label>
                                            <Form.Item
                                                name='image'
                                                rules={[
                                                    {
                                                        required: false,
                                                    },
                                                ]}
                                            >
                                                <ImageUpload
                                                    id='image'
                                                    name='image'
                                                    view={view.image}
                                                    file={formValues.image}
                                                    onChange={(e) => changeFile('image', e.target.files)}
                                                />

                                            </Form.Item>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                <div className='col-12' >
                                    <Button
                                        type='submit'
                                        variant=''
                                        // onClick={() => popForm('showOne', true)}
                                        className='but-all w-100'
                                    >تعديل البيانات</Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            <AddPop
                showOne={formPop.showOne}
                showTwo={formPop.showTwo}
                handleshow={(e) => {
                    popForm('showOne', false)
                    postData(e)
                    popForm('showTwo', true)
                }}
                handleCloseOne={() => popForm('showOne', false)}
                handleCloseTwo={() => popForm('showTwo', false)}
                post={post.progress}
            />
            <ErrorPop
                showError={formPop.showError}
                handleCloseError={() => popForm('showError', false)}
                errorMessage={formPop.errorMessage}
            />
        </>
    )
}

