import { Checkbox, Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../api/axiosInstance'
import { Button } from 'react-bootstrap'
import { PageHeader } from '../components/global'
import { AddPop, ErrorPop } from '../components/forms/PopForm'
export default function Settings() {
    const [survey, setSurvey] = useState({
        key: 'survey',
        value: ''
    })
    const [search, setSearch] = useState({
        key: 'search',
        value: ''
    })
    const changeSurvey = (value) => {
        setSurvey((state) => {
            return { ...state, value: value }
        })
    }
    const changeSearch = (value) => {
        setSearch((state) => {
            return { ...state, value: value }
        })
    }
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showError: false,
        errorMessage: '',
    })
    const [post, setPost] = useState({
        progress: 0,
        count: 0,
    })
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }

    const onPost = async (data, reload) => {
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }
        const option = { onUploadProgress: UploadProgress }
        await axiosInstance.post('/setting/api/v1/settings/', data, option)
            .catch(error => {
                popForm('errorMessage', 'حدث خطأ ما يرجى التأكد من الأتصال')
            }).then(res => {
                if (reload) {
                    if (res.status === 201) {
                        console.log('reload')
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000);
                    }
                } else {
                    console.log('not reload')
                }

            })
    }

    const onSettings = (e) => {
        e.preventDefault()
        if (survey.value) onPost(survey, false)
        if (search.value) onPost(search, true)

    }
    useEffect(() => {
        async function getData() {
            await axiosInstance.get(`/setting/api/v1/settings/find/?key=survey`)
                .then(res => {
                    changeSurvey(res.data.value)
                })
            await axiosInstance.get(`/setting/api/v1/settings/find/?key=search`)
                .then(res => {
                    changeSearch(res.data.value)
                })
         
        }
        getData()
    }, [])
    return (
        <div className='m-3'>
            <div class="col-xl-12 m-2">
                <PageHeader
                    title={`الأعدادات العامة`}
                    links={[]}
                />
            </div>
            <div class="container-fluid settings">
                <div class="row justify-content-center align-items-center g-2">

                    <div class="col-12 d-flex justify-content-between p-3 border-bottom">
                        <h5 className='f-bold'><i class="fa-solid fa-gears" style={{ color: '#0c7676' }}></i> الأعدادات العامة</h5>
                        <Button
                            type='button'
                            variant=''
                            onClick={() => popForm('showOne', true)}
                            className='but-all'
                        >
                            حفظ الأعدادات
                        </Button>
                    </div>
                    <div class="col-12 pb-5">
                        <h3 className='settings-title'>الأستمارات</h3>
                        <p className='settings-info'>أيقاف الأستمارات بسكل عام اي لن تظهر اي معلومة عن الأستمارات في الموقع الألكتروني</p>
                        <Radio.Group onChange={(e) => changeSurvey(e.target.value)} value={survey.value}>
                            <Radio value={"true"}>تفعيل</Radio>
                            <Radio value={"false"}>ايقاف</Radio>
                        </Radio.Group>
                    </div>
                    <hr />
                    <div class="col-12 pb-5">
                        <h3 className='settings-title'>أستمارة البحوث العلمية</h3>
                        <p className='settings-info'>أيقاف أستمارة البحوث العلمية فقط بسكل عام اي لن تظهر اي معلومة عن أستمارة في الموقع الألكتروني </p>
                        <Radio.Group onChange={(e) => changeSearch(e.target.value)} value={search.value}>
                            <Radio value={"true"}>تفعيل</Radio>
                            <Radio value={"false"}>ايقاف</Radio>
                        </Radio.Group>
                    </div>
                    <AddPop
                        showOne={formPop.showOne}
                        showTwo={formPop.showTwo}
                        handleshow={(e) => {
                            popForm('showOne', false)
                            onSettings(e)
                            popForm('showTwo', true)
                        }}
                        handleCloseOne={() => popForm('showOne', false)}
                        handleCloseTwo={() => popForm('showTwo', false)}
                        post={post.progress}
                    />
                    <ErrorPop
                        showError={formPop.showError}
                        handleCloseError={() => popForm('showError', false)}
                        errorMessage={formPop.errorMessage}
                    />
                </div>
            </div>
        </div>
    )
}
