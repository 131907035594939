import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'

export default function CardHeader(props) {
    const title = props.title;
    const count = props.count;
    const value = props.value;
    const text = props.text;
    const icon = props.icon;
    const color = props.color;
    return (
        <Card className="card-stats card-shadow mb-4 mb-xl-0">
            <Card.Body>
                <Row>
                    <div className="col">
                        <Card.Title
                            className="text-uppercase text-muted mb-0 fs-15"
                        >
                            {title}
                        </Card.Title>
                        <span className="font-weight-bold mb-0 fs-22">
                            {count}
                        </span>
                    </div>
                    <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow" style={{ backgroundColor: color }}>
                            <i className={icon} />
                        </div>
                    </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                    {/* <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> {value}%
                    </span>{" "} */}
                    <span className="text-nowrap fs-13"> {text} </span>
                </p>
            </Card.Body>
        </Card>
    )
}
