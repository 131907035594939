import React, { useState } from 'react';
import { CardsForm } from '../cards/Card';
import { CustomDatePicker, CustomInput, CustomTextArea, CustomSelect, CustomTreeSelect } from '../forms/CustomForms';
import { Checkbox, Form, } from 'antd';
import { ImageUpload } from '../forms/ImageUpload';
import { Table } from 'react-bootstrap';
import axiosInstance from '../../api/axiosInstance';
import { useEffect } from 'react';
import { AddPop, ErrorPop } from '../forms/PopForm';
import { Link } from 'react-router-dom';
import moment from 'moment';

export function AddFormNews() {
    const [form] = Form.useForm();
    // forms valus to post data
    const [formValues, setFormValues] = useState({
        image: '',
        is_active: true
    })
    // select data in form
    const [formData, setFormData] = useState({
        list: [],
        managements: [],
    })
    // search values to select forms
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    // search values to select forms
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showError: false,
        errorMessage: '',
    })
    const [view, setView] = useState({
        image: null,
    })
    const [post, setPost] = useState({
        progress: 0,
        count: 0,
    })
    // func to change values in form
    const change = (name, value) => {
        setFormValues((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change file values in form
    const changeFile = (name, value) => {
        if (value[0]) {
            const file = URL.createObjectURL(value[0]);
            setView((state) => {
                return { ...state, [name]: file }
            })
            setFormValues((state) => {
                return { ...state, [name]: value[0] }
            })
        } else {
            setView((state) => {
                return { ...state, [name]: null }
            })
            setFormValues((state) => {
                return { ...state, [name]: '' }
            })
        }
    }
    // func to get data from api and push it to state
    const getFormData = (name, value) => {
        setFormData((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to search in api and push it to state
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change values in pop form
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    // get data from api
    useEffect(() => {
        //get death
        async function getData() {
            await axiosInstance.get(`/news/api/v1/news/`)
                .then(res => {
                    getFormData('list', res.data.results);
                })
        }
        getData()
    }, [post.count])

    // post data to api

    const postData = async (event) => {
        event.preventDefault();
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }
        const option = {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: UploadProgress
        }
        const fileData = new FormData();
        let key;
        for (key in formValues) {
            fileData.append(key, formValues[key]);
        }
        await axiosInstance.post('/news/api/v1/news/', formValues, option)
            .catch(error => {
                popForm('showTwo', false)
                popForm('showError', true)
                changePost('progress', 0)
                if (error.request.status === 400) {
                    popForm('errorMessage', 'هنالك نقص في المعلومات')
                } else if (error.message === 'Network Error') {
                    popForm('errorMessage', 'لا يوجد أتصال بالانترنيت')
                }
                else if (error.request.status === 406) {
                    popForm('errorMessage', 'اسم المستخدم يجب ان يكون مكون من اربع كاركترات')
                }
            }).then(res => {
                if (res.status === 201) {
                    popForm('showTwo', false)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
            })
    }
    const onFinash = (values) => {
        const value = {}
        for (let key in values) {
            if (key === 'image') {
                continue;
            } else {
                value[key] = values[key]
            }
        }
        setFormValues((state) => {
            return { ...state, ...value }
        })
        popForm('showOne', true)
    }

    console.log(formValues)
    return (
        <>
            <Form form={form} onFinish={(values) => onFinash(values)}>
                <div className='container-fluid h-100'>
                    <div class="row justify-content-start g-2 ">
                        <div class="col-12">
                            <CardsForm hasBody title='البيانات الأساسية' hasButton>
                                <div className='row justify-content-start g-2'>
                                    <div className='col-md-4 col-12'>
                                        <label className='optional'>عنوان الخبر</label>
                                        <Form.Item
                                            name='title'
                                            className='my-0 py-0'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'هذا الحقل الزامي',
                                                },

                                            ]}
                                            hasFeedback
                                        >
                                            <CustomInput type="text" icon='subtitles' name='title' placeholder="عنوان الخبر" />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <label className='optional'>المدون الخبرية</label>
                                        <Form.Item
                                            name='author'
                                            className='my-0 py-0'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'هذا الحقل الزامي',
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <CustomInput type="text" icon='newspaper' name='author' placeholder="الوكالة الخبرية" />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <label className='optional'>نوع الخبر</label>
                                        <Form.Item
                                            name='type'
                                            className='my-0 py-0'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'هذا الحقل الزامي',
                                                },
                                            ]}
                                            hasFeedback
                                            initialValue={1}
                                        >
                                            <CustomSelect
                                                icon='grade'
                                                name='type'
                                            >
                                                <option value={0}>عاجل</option>
                                                <option value={1}>خبر اعتيادي</option>

                                            </CustomSelect>
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <label className='optional'>أولوية الخبر</label>
                                        <Form.Item
                                            name='sort'
                                            className='my-0 py-0'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'هذا الحقل الزامي',
                                                },
                                            ]}
                                            hasFeedback
                                            initialValue={1}
                                        >
                                            <CustomSelect
                                                icon='sort'
                                                name='sort'
                                            >
                                                <option value={0}>مهم</option>
                                                <option value={1}>اعتيادي</option>

                                            </CustomSelect>
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <label className='optional'>تاريخ النشر</label>
                                        <Form.Item
                                            name='publication_date'
                                            className='my-0 py-0'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'هذا الحقل الزامي',
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <CustomDatePicker format="YYYY-MM-DD HH:mm:ss" showTime name='video_link' placeholder="HH:mm:ss YYYY-MM-DD" />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <label>رابط الفيديو</label>
                                        <Form.Item
                                            name='video_link'
                                            className='my-0 py-0'
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <CustomInput type="text" icon='link' name='video_link' placeholder="رابط الفيديو" />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-12 col-12'>
                                        <label >الكلمات المفتاحية</label>
                                        <Form.Item
                                            name='tags'
                                            className='my-0 py-0'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'يجب أن تحتوي على كلمتين على الأقل',
                                                    type: 'array',
                                                    min: 2,
                                                },
                                            ]}
                                            hasFeedback
                                            initialValue={['أبناء_المهندس', 'تجمع_المهندس']}
                                            extra='عدد الكلمات يجب ان يكون اكثر من كلمة واحدة'
                                        >
                                            <CustomSelect
                                                icon='tags'
                                                name='tags'
                                                mode='tags'
                                                placeholder="اختر او اكتب الكلمة"
                                            >
                                                <option value={'أبناء_المهندس'}>أبناء_المهندس</option>
                                                <option value={'تجمع_المهندس'}>تجمع_المهندس</option>
                                            </CustomSelect>
                                        </Form.Item>
                                    </div>
                                    <div className='col-12'>
                                        <Form.Item
                                            name='is_active'
                                            className='my-0 py-0'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'هذا الحقل الزامي',
                                                },
                                            ]}
                                            initialValue={true}
                                        >
                                            <Checkbox defaultChecked={true} name='is_active'>نشر الخبر</Checkbox>
                                        </Form.Item>
                                    </div>
                                    <div className='col-12'>
                                        <label >موجز</label>
                                        <Form.Item
                                            name='summary'
                                            className='my-0 py-0'
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <CustomTextArea icon='description' rows={5} name='summary' placeholder="موجز" />
                                        </Form.Item>
                                    </div>
                                    <div className='col-12'>
                                        <label >الخبر</label>
                                        <Form.Item
                                            name='text'
                                            className='my-0 py-0'
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <CustomTextArea icon='description' rows={5} name='text' placeholder="الخبر" />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-3 col-12'>
                                        <label >الصورة</label>
                                        <Form.Item
                                            name='image'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'هذا الحقل الزامي',
                                                },
                                            ]}
                                        >
                                            <ImageUpload
                                                id='image'
                                                name='image'
                                                view={view.image}
                                                file={formValues.image}
                                                onChange={(e) => changeFile('image', e.target.files)}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </CardsForm>
                        </div>
                        <div className='col-12'>
                            <CardsForm hasBody title='اخر إضافة'>
                                <div className='table-responsive'>
                                    <Table className="align-items-center table-flush">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">عنوان الخبر</th>
                                                <th scope="col">الوكالة</th>
                                                <th scope="col">نوع الخبر</th>
                                                <th scope="col">أولوية الخبر</th>
                                                <th scope="col">حالة الخبر</th>
                                                <th scope="col">تاريخ النشر</th>
                                                <th scope="col">تاريخ الرفع</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formData.list.map(item => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td width='20%'>
                                                            <Link to={`/news/list/id/${item.id}/name/${item.title}`}> {item.title}</Link>
                                                        </td>
                                                        <td width='20%'>{item.author}</td>
                                                        <td width='20%'>{item.type === 0 ? 'عاجل' : "خبر اعتيادي"}</td>
                                                        <td width='20%'>{item.sort === 0 ? 'مهم' : "اعتيادي"}</td>
                                                        <td width='20%'>{item.is_active ? 'منشور' : "غير منشور"}</td>
                                                        <td dir='ltr' width={1}>{moment(item.publication_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                        <td dir='ltr' width={1}>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardsForm>
                        </div>
                    </div>
                </div>
            </Form>
            <AddPop
                showOne={formPop.showOne}
                showTwo={formPop.showTwo}
                handleshow={(e) => {
                    popForm('showOne', false)
                    postData(e)
                    popForm('showTwo', true)
                }}
                handleCloseOne={() => popForm('showOne', false)}
                handleCloseTwo={() => popForm('showTwo', false)}
                post={post.progress}
            />
            <ErrorPop
                showError={formPop.showError}
                handleCloseError={() => popForm('showError', false)}
                errorMessage={formPop.errorMessage}
            />
        </>
    )
}


