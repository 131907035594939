import React from 'react';
import LinkSidebar from './items/Link';
import { list, list2, list3, list4, listUsers } from './list';
import LinkItem from './items/LinkItem';
import { Offcanvas } from 'react-bootstrap';
import { userType, groupType } from '../../var_func/var_func';
export default function Sidebar({ open, handleClose }) {

  return (
    <div className='sidebar-layout'>
      <Offcanvas
        className='admin-offcanvas'
        show={open}
        onHide={handleClose}
        placement='end'
        responsive="xl"
        style={{
          width: 250
        }}
      >
        <Offcanvas.Body>
          <div
            id='sidebar'
            className='admin-sidebar'
            style={{
              width: open ? 250 : 0
            }}
          >
            <div className='sidebar-content'>
              <a href='/' className='logo-sidebar'>
                <img alt='...' src={require('../../styles/images/dashboard_img/logo.png')} />
              </a>
              <div className='links-sidebar'>
                {/*-------------------------------------
                 The highest-ranking powers in the portal 
                --------------------------------------- */}
                <LinkSidebar
                  index={30}
                  title={'الرئيسية'}
                  icon={'fa-solid fa-house'}
                  color={'#009FBD'}
                  path={'/'}
                />
                {[0, 1, 2, 3, 4].includes(userType) && [0, 1].includes(groupType) ? <LinkItem list={list} /> : null}
                {[0, 2].includes(userType) && [0, 1].includes(groupType) ? <LinkItem list={list2} /> : null}
                {[0, 3].includes(userType) && [0, 1].includes(groupType) ? <LinkItem list={list3} /> : null}
                {[0, 4].includes(userType) && [0, 1].includes(groupType) ? <LinkItem list={list4} /> : null}
                {[0].includes(userType) && [0, 2].includes(groupType) ? <LinkItem list={listUsers} /> : null}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}
