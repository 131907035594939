import React from 'react'
import { Card, Row, Button, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export default function Cards(props) {
    const title = props.title;
    const subtitle = props.subtitle;
    const hasButton = props.hasButton;
    const hasBody = props.hasBody;
    const path = props.path;

    return (
        <Card className="card-shadow border-0">
            <Card.Header className="border-0">
                <Row className="align-items-center">
                    <div className="col-10">
                        {subtitle && <p className='text-uppercase text-muted ls-1 mb-1 fs-13'>{subtitle}</p>}
                        <p className="mb-0 fs-card fs-22">{title}</p>
                    </div>
                    {hasButton &&
                        <div className="col text-right">
                            <Button
                                as={Link}
                                variant=""
                                className='cards-buts'
                                to={path}
                                size="sm"
                            >
                                مشاهدة الجميع
                            </Button>
                        </div>
                    }
                </Row>
            </Card.Header>
            {hasBody ?
                <Card.Body>
                    {props.children}
                </Card.Body>
                :
                <>
                    {props.children}
                </>
            }
        </Card>
    )
}
export function CardsForm(props) {
    const { title, subtitle, className, hasButton } = props;

    return (
        <Card className={"card-shadow border-0 mb-2 " + className} >
            <Card.Header className="border-0 card-header-form ">
                <Row className="align-items-center">
                    <div className="col-12">
                        {subtitle && <p className='text-uppercase text-muted ls-1 mb-1 fs-13'>{subtitle}</p>}
                        <p className="mb-0 fs-card fs-18">{title}</p>
                    </div>
                    {hasButton &&
                        <div className="col text-right">
                            <Button
                                variant=""
                                className='form-buts'
                                type='submit'
                                size="sm"
                            >
                                أضافة البيانات
                            </Button>
                        </div>
                    }
                </Row>
            </Card.Header>
            <Card.Body>
                {props.children}
            </Card.Body>
        </Card>
    )
}
