import { Avatar, Drawer } from 'antd';
import React, { useState } from 'react'
import { CustomInput, CustomSelect } from '../forms/CustomForms';
import axiosInstance from '../../api/axiosInstance';
import { useEffect } from 'react';
import { Button, Offcanvas, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { defaultsImage, downlodXlsx } from '../../var_func/var_func';
import { useCookies } from 'react-cookie';
import { PaginationPage } from '../global';
import { ReportPop } from '../forms/PopForm';
import { customDataSurvey } from '../../pages/survey/data';
import moment from 'moment';

export function SortSurvey(prpos) {
    const { onClose, open, type } = prpos;
    const [page, setPage] = useCookies('');
    const { sort_survey_list } = page;
    const [sortValues, setSortValues] = useState({
        type: ''
    })
    const [SortData, setSortData] = useState({
        managements: [],
        list: [],
        listReport: [],
        listCount: 0
    })
    const [formSearch, setFormSearch] = useState({
        managements: '',
    })
    const [pop, setPop] = useState({
        one: false,
        two: false,
        report: false,
    });
    const ondrawer = (name, value) => {
        setPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const [drawer, setDrawer] = useState(false);
    const change = (name, value) => {
        setSortValues((state) => {
            return { ...state, [name]: value }
        })
    }
    const getFormData = (name, value) => {
        setSortData((state) => {
            return { ...state, [name]: value }
        })
    }
    const searchForm = (name, value) => {
        setFormSearch((state) => {
            return { ...state, [name]: value }
        })
    }
    const showChildrenDrawer = () => {
        setDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setDrawer(false);
    };
    const path = '/contributions/list/';
    const url = '/contributions/api/v1/contributions/';
    const title = 'أستمارات البحوث الحامعية';
    useEffect(() => {
        //get death
        async function getData() {
            const sort = `type=${sortValues.type}`;
            if (drawer) {
                await axiosInstance.get(`${url}?${sort}&page_size=100&page=${sort_survey_list ? sort_survey_list : 1}`)
                    .then(res => {
                        getFormData('list', res.data.results)
                        getFormData('listCount', res.data.count)
                        if (res.data.count > 0 && res.data.results.length === 0) setPage('sort_survey_list', 1)
                    }).catch(err => {
                        if (err.response.status === 304) {
                            setPage('sort_survey_list', 1)
                        }
                    })
            }
        }
        getData()
    }, [sortValues, drawer, sort_survey_list])
    useEffect(() => {
        //get death
        async function getData() {
            if (pop.report) {
                const sort = `type=${sortValues.type}`;
                if (drawer) {
                    await axiosInstance.get(`${url}?${sort}&page_size=${SortData.listCount}`)
                        .then(res => {
                            getFormData('listReport', res.data.results)
                        }).catch(err => {
                            ondrawer('report', false)
                        })
                }
            }
        }
        getData()
    }, [sortValues, drawer, sort_survey_list, pop.report])

    const onButtonReport = () => {
        downlodXlsx(customDataSurvey(SortData.listReport), 'تقرير البحوث الحامعية'  + Math.floor(Math.random() * 100000))
    }

    return (
        <>
            <Offcanvas show={open} onHide={onClose} className='offcanvas-edit'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>فرز {title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div class="container-fluid">
                        <div className='row justify-content-center g-2'>
                            <div className='col-12'>
                                <label>الفئة</label>
                                <CustomSelect
                                    icon='escalator_warning'
                                    name='type'
                                    onChange={(e) => change('type', e === undefined ? '' : e)}
                                    onClear={() => change('type', '')}
                                >
                                    <option value={1}>ذوي الشهداء</option>
                                    <option value={2}>ذوي الجرحى</option>
                                    <option value={3}>منتسب في الحشد</option>
                                    <option value={0}>لاشيء مما سبق</option>
                                </CustomSelect>
                            </div>
                        </div>
                        <div className='row justify-content-center g-2 mt-5 '>
                            <Button variant="" className='but-all w-100' onClick={showChildrenDrawer}>
                                معاينة النتائج
                            </Button>
                        </div>
                    </div>
                    <Offcanvas show={drawer} onHide={onChildrenDrawerClose} className='offcanvas-edit'>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>نتائج الفرز</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            {SortData.listCount > 0 ?
                                <>
                                    <p>عدد النتائج : {SortData.listCount}</p>
                                    <div className='table-responsive'>
                                        <Table className="align-items-center table-flush">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>ت</th>
                                                    <th scope="col">المشارك</th>
                                                    <th scope="col">عنوان البحث</th>
                                                    <th scope="col">الجامعة</th>
                                                    <th scope="col">الكلية</th>
                                                    <th scope="col">القسم</th>
                                                    <th scope="col">تاريخ المشاركة</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {SortData.list.map((item, index) => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{(((sort_survey_list ? sort_survey_list : 1) - 1) * 100) + (index + 1)}</td>
                                                            <th scope="row">
                                                                <Link to={`${path}item/id/${item.id}/name/${item.name}`}>
                                                                    {item.name}
                                                                </Link>
                                                            </th>
                                                            <td>{item.search_title}</td>
                                                            <td>{item.university}</td>
                                                            <td>{item.college}</td>
                                                            <td>{item.section}</td>
                                                            <td dir='ltr'>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                                :
                                <div className='d-flex flex-column justify-content-center align-items-center py-5'>
                                    <i class="fa-solid fa-table" style={{ fontSize: 75, color: '#aaaa' }}></i>
                                    <p className='py-2' style={{ fontSize: 25, color: '#aaaa' }}>لا توجد بيانات</p>
                                </div>
                            }
                            <div className="col-12">
                                <PaginationPage
                                    defaultCurrent={!sort_survey_list ? 1 : Number(sort_survey_list)}
                                    total={SortData.listCount}
                                    defaultPageSize={100}
                                    onChange={(e) => setPage('sort_survey_list', e === undefined ? '' : e)}
                                />
                            </div>
                            {SortData.listCount > 0 &&
                                <div className='row justify-content-center g-2 mt-5 '>
                                    <Button variant="" className='but-all w-100' onClick={() => ondrawer('report', true)}>
                                        تحميل البيانات
                                    </Button>
                                </div>
                            }
                        </Offcanvas.Body>
                    </Offcanvas>
                </Offcanvas.Body>
            </Offcanvas>
            <ReportPop
                open={pop.report}
                title={title}
                onClose={() => ondrawer('report', false)}
                onOK={onButtonReport}
            />
        </>
    )
}


